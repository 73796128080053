import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Импорт запроса на бэк:
import { apiLogoutUser } from '../../api/api-userAuth';

// Импорт селекторов (доступ к State):
import { selAuthorization } from '../../redux/selectors/selectorMain';

// Импорт Action (обнуление ошибки):
import { actDefaultError, actDefaultSuccess } from '../../redux/actions/helpers';
// Импорт из библиотеки:
import { Box } from '@mui/system';

// Импорт медиазапросов из библиотеки:
import useMediaQuery from '@mui/material/useMediaQuery';

// Импорт моих компонентов:
import Navigation from '../section-Navigation/Navigation';
import { ButtonStyleNav } from '../../components/Elements/Button/ButtonStyle';
// Иморт моих констант:
import { BUILD_HUB,} from '../../helpers/constants';

// Импорт путей:
import route from '../../routes/routes';

// Импорт стилей:
import '../../App.css';
import style from './Header.module.css';
import { LogoStyle } from '../../components/Elements/Style/TextStyle';


export default function Header() {

  const isAuthorized = useSelector(selAuthorization);
  const mobile = useMediaQuery('(max-width:767px)');
  const tablet = useMediaQuery('(max-width:1199px)');
  const desktop = useMediaQuery('(min-width:1200px)');

  // Dispatch:
  const dispatch = useDispatch();

  // Так как у нас ошибка появляется на экране, то ее нужно очищать когда мы переходим с логинизации на регистрацию:
  function fnDefaulState() {
    dispatch(actDefaultError())
    dispatch(actDefaultSuccess())
  }

  // Ф-я выхода Юзера:
  const fnExit = event => {
    dispatch(apiLogoutUser());
  }; 
  
  return (
    <section className="App-header">
      <Box sx={{ width: { xs: '320px', sm: '768px', lg: '1200px' },}}>
        {/* ==================================== 1.1) БЕЗ РЕГИСТРАЦИИ - РЕНДЕР - ДЕСКТОП =============================  */}
        {desktop && !isAuthorized ? (
        <Box sx={{ display: {sm: 'flex'}, justifyContent: 'space-between', alignItems: 'center'}} >
          {/* <div> */}
          {/* FIXME: style.title_active - не дйствует */}
          <NavLink to={route.home} className="link" activeClassName={style.title_active}>
              <LogoStyle sx={{fontSize: {sm: '26px'} }}>
              {BUILD_HUB}
              </LogoStyle>
          </NavLink>
          {/* </div> */}
          <div>
            <Navigation/>
          </div>
          <div>
            <NavLink to={route.login} onClick={fnDefaulState} className="link">
              <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {sm: '14px'}}}>Log in</ButtonStyleNav>
            </NavLink>
            <NavLink to={route.register} onClick={fnDefaulState} className="link">
              <ButtonStyleNav sx={{display: 'block', margin: '0px', fontSize: {sm: '14px'}}}>Registration</ButtonStyleNav>
            </NavLink>
          </div>
        </Box>
        ) : null}
        {/* ===================================== 1.2) БЕЗ РЕГИСТРАЦИИ - РЕНДЕР - ПЛАНШЕТ ============================= */}
        {!mobile && tablet && !desktop && !isAuthorized ? (
          <div>
            <div className={style.flexbox}>
              <NavLink to={route.login} onClick={fnDefaulState} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {sm: '14px'}}}>Log in</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.home} className="link" activeClassName={style.title_active}>
                <LogoStyle sx={{fontSize: {sm: '26px'}, }}>
                  {BUILD_HUB}
                </LogoStyle>
              </NavLink>
              <NavLink to={route.register} onClick={fnDefaulState} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', fontSize: {sm: '14px'}}}>Registration</ButtonStyleNav>
              </NavLink>
            </div>
            <Navigation/>
          </div>
        ) : null}
        {/* =================================== 1.3) БЕЗ РЕГИСТРАЦИИ - РЕНДЕР - ТЕЛЕФОН =======================================*/}
        {mobile && !isAuthorized ? (
          <div>
              <NavLink to={route.home} className="link">
              <LogoStyle sx={{display: 'inline', fontSize: {xs: '20px', sm: '26px'}, margin: '0px' }}>
                {BUILD_HUB}
              </LogoStyle>
              </NavLink>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <NavLink to={route.login} onClick={fnDefaulState} className="link">
                  <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {xs: '12px', sm: '14px'}}}>Log in</ButtonStyleNav>
                </NavLink>
                <NavLink to={route.register} onClick={fnDefaulState} className="link">
                  <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {xs: '12px', sm: '14px'}}}>Registration</ButtonStyleNav>
                </NavLink>
              </div>
              <Navigation/>
          </div>
        ) : null}
        {/* ==================================== 2.1) C РЕГИСТРАЦИЕЙ  - РЕНДЕР - ДЕСКТОП =============================  */}
        {desktop && isAuthorized ? (
            <Box sx={{ display: {sm: 'flex'}, justifyContent: 'space-between', alignItems: 'center'}} >
              <NavLink to={route.home} className="link" activeClassName={style.title_active}>
                <LogoStyle sx={{fontSize: {sm: '26px'}, }}>
                  {BUILD_HUB}
                </LogoStyle>
              </NavLink>
            <div>
              <Navigation/>
            </div>
            <div>
              <NavLink to={route.login} onClick={fnExit} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {sm: '14px'}}}>Вихід</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.user} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', fontSize: {sm: '14px'}}}>Мій кабінет</ButtonStyleNav>
              </NavLink>
            </div>
          </Box>
        ) : null}
        {/* ===================================== 2.2) C РЕГИСТРАЦИЕЙ  - РЕНДЕР - ПЛАНШЕТ ============================= */}
        {!mobile && tablet && !desktop && isAuthorized ? (
          <div>
            <div className={style.flexbox}>
              <NavLink to={route.login} onClick={fnExit} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {sm: '14px'}}}>Вихід</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.home} className="link" activeClassName={style.title_active}>
                <LogoStyle sx={{fontSize: {sm: '26px'}, }}>
                  {BUILD_HUB}
                </LogoStyle>
              </NavLink>
              <NavLink to={route.user} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', fontSize: {sm: '14px'}}}>Мій кабінет</ButtonStyleNav>
              </NavLink>
            </div>
            <Navigation/>
          </div>
        ) : null}
        {/* =================================== 2.3) C РЕГИСТРАЦИЕЙ  - РЕНДЕР -  ТЕЛЕФОН  =======================================*/}
        {mobile && isAuthorized ? (
          <div>
            <NavLink to={route.home} className="link">
                <LogoStyle sx={{display: 'inline', fontSize: {xs: '20px', sm: '26px'}, margin: '0px' }}>
                  {BUILD_HUB}
                </LogoStyle>
            </NavLink>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <NavLink to={route.login} onClick={fnExit} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {xs: '12px', sm: '14px'}}}>Вихід</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.user} className="link">
                <ButtonStyleNav sx={{display: 'block', margin: '0px', marginX: 'auto', fontSize: {xs: '12px', sm: '14px'}}}>Мій кабінет</ButtonStyleNav>
              </NavLink>
            </div>
            <Navigation/>
          </div>
          ) : null}
      </Box>
    </section>
  );
}
