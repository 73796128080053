import { createReducer } from '@reduxjs/toolkit';

import {
  actRegister,
  // actRegisterError,
  actRegisterRequest,

  // actLogin,
  // actLoginError,
  actLoginRequest,

  // actLogout,
  // actLogoutError,
  actLogoutRequest,

  // actCurrentUser,
  // actCurrentUserError,
  // actCurrentUserRequest,

  actUpdateUser,
  // actUpdateUserError,
  actUpdateUserRequest,

  actUpdateUserEmail,
  // actUpdateUserEmailError,
  actUpdateUserEmailRequest,

  actUpdateUserPass,
  // actUpdateUserPassError,
  actUpdateUserPassRequest,

  actForgotUserPass,
  // actForgotUserPassError,
  actForgotUserPassRequest,

  actUserSendEmail,
  // actUserSendEmailError,
  actUserSendEmailRequest,

  // actDeleteUser,
  // actDeleteUserError,
  actDeleteUserRequest,
} from '../actions/userAuth';

import {
  // actCreateCompany,
  // actCreateCompanyError,
  actCreateCompanyRequest,

  // actGetUserCompany,
  // actGetUserCompanyError,
  actGetUserCompanyRequest,

  actUpdateCompanyContacts,
  // actUpdateCompanyContactsError,
  actUpdateCompanyContactsRequest,

  // actDeleteCompanyPhoto,
  // actDeleteCompanyPhotoError,
  actDeleteCompanyPhotoRequest,

  // actDeleteCompany,
  // actDeleteCompanyError,
  actDeleteCompanyRequest,

  // actUpdateCompanyStatus,
  // actUpdateCompanyStatusError,
  actUpdateCompanyStatusRequest,

  actUpdateCompany,
  // actUpdateCompanyError,
  actUpdateCompanyRequest,

  // actUpdateCompanyPhoto,
  // actUpdateCompanyPhotoError,
  actUpdateCompanyPhotoRequest,

  // actUpdateCompanyLogo,
  // actUpdateCompanyLogoError,
  actUpdateCompanyLogoRequest,

  // actGetUserCompanyPhoto,
  // actGetUserCompanyPhotoError,
  actGetUserCompanyPhotoRequest,
} from '../actions/userCompany';

import {
  // actCreateNews,
  // actCreateNewsError,
  actCreateNewsRequest,

  // actGetUserNews,
  // actGetUserNewsError,
  actGetUserNewsRequest,

  // actUpdateUserNews,
  // actUpdateUserNewsError,
  actUpdateUserNewsRequest,

  // actUpdateUserNewsPhoto,
  // actUpdateUserNewsPhotoError,
  actUpdateUserNewsPhotoRequest,
  
  // actUpdateUserNewsStatusAll,
  // actUpdateUserNewsStatusAllError,
  actUpdateUserNewsStatusAllRequest,

  // actUpdateUserNewsStatusSuper,
  // actUpdateUserNewsStatusSuperError,
  actUpdateUserNewsStatusSuperRequest,

  // actDeleteNews,
  // actDeleteNewsError,
  actDeleteNewsRequest,
} from '../actions/userNews';

import {
// actGetCompany,
// actGetCompanyError,
actGetCompanyRequest,

// actGetCompanyCurrent,
// actGetCompanyCurrentError,
actGetCompanyCurrentRequest,
  
// actGetCompanyDeveloper,
// actGetCompanyDeveloperError,
actGetCompanyDeveloperRequest,

// actGetCompanyBuilder,
// actGetCompanyBuilderError,
actGetCompanyBuilderRequest,

// actGetCompanyDesigner,
// actGetCompanyDesignerError,
actGetCompanyDesignerRequest,

// actGetCompanyFavorite,
// actGetCompanyFavoriteError,
actGetCompanyFavoriteRequest,

} from '../actions/company';

import { 
  // actNewsGlobal, 
  // actNewsGlobalError, 
  actNewsGlobalRequest, 

  // actNewsLocal, 
  // actNewsLocalError, 
  actNewsLocalRequest,

  // actNewsLocalCurrent, 
  // actNewsLocalCurrentError, 
  actNewsLocalCurrentRequest,

  // actNewsPublic, 
  // actNewsPublicError,
  actNewsPublicRequest,
} from '../actions/news';

import {actDefaultSuccess} from '../actions/helpers'

// --------------------------------------------------> 

// Дефолтные значения
// const defaulMessage = {
//   // favorite, _id - указали конкретно дефолтное значение так как есть в коде привязка к глоб.State
//   status: null,
//   // _id: null
// }


const successReducer = createReducer(null, {

  // Сброс в дефолтное состояние:
  [actDefaultSuccess]: () => null,

  // // ------------------------------------------- Список Компаний:
  [actGetCompanyRequest]: () => null,
  // [actGetCompany]: () => false,
  // [actGetCompanyError]: () => false,

  [actGetCompanyCurrentRequest]: () => null,
  // [actGetCompanyCurrent]: () => false,
  // [actGetCompanyCurrentError]: () => false,

  [actGetCompanyDeveloperRequest]: () => null,
  // [actGetCompanyDeveloper]: () => false,
  // [actGetCompanyDeveloperError]: () => false,

  [actGetCompanyBuilderRequest]: () => null,
  // [actGetCompanyBuilder]: () => false,
  // [actGetCompanyBuilderError]: () => false,

  [actGetCompanyDesignerRequest]: () => null,
  // [actGetCompanyDesigner]: () => false,
  // [actGetCompanyDesignerError]: () => false,

  [actGetCompanyFavoriteRequest]: () => null,
  // [actGetCompanyFavorite]: () => false,
  // [actGetCompanyFavoriteError]: () => false,

  // // ------------------------------------------- Список Новостей:
  [actNewsGlobalRequest]: () => null,
  // [actNewsGlobal]: () => false,
  // [actNewsGlobalError]: () => false,

  [actNewsLocalRequest]: () => null,
  // [actNewsLocal]: () => false,
  // [actNewsLocalError]: () => false,

  [actNewsLocalCurrentRequest]: () => null,
  // [actNewsLocalCurrent]: () => false,
  // [actNewsLocalCurrentError]: () => false,

  [actNewsPublicRequest]: () => null,
  // [actNewsPublic]: () => false,
  // [actNewsPublicError]: () => false,

  // ------------------------------------------- Юзер - Авторизация:
  [actRegisterRequest]: () => null,
  [actRegister]: () => 'SendEmail',
  // [actRegisterError]: () => false,

  [actLoginRequest]: () => null,
  // [actLogin]: () => false,
  // [actLoginError]: () => false,

  [actLogoutRequest]: () => null,
  // [actLogout]: () => false,
  // [actLogoutError]: () => false,

  [actUpdateUserRequest]: () => null,
  [actUpdateUser]: () => 'UpdateInfo',
  // При ошибке не нужно потому что при запросе всегда сбрасываем:
  // [actUpdateUserError]: () => null,

  [actUpdateUserEmailRequest]: () => null,
  [actUpdateUserEmail]: () => 'UpdateEmail',

  [actUpdateUserPassRequest]: () => null,
  [actUpdateUserPass]: () => 'UpdatePass',

  [actForgotUserPassRequest]: () => null,
  [actForgotUserPass]: () => 'ForgotPass',

  [actUserSendEmailRequest]: () => null,
  [actUserSendEmail]: () => 'SendEmail',

  [actDeleteUserRequest]: () => null,
  // [actDeleteUser]: () => false,
  // [actDeleteUserError]: () => false,

  // ------------------------------------------- Юзер - Компания:
  [actCreateCompanyRequest]: () => null,
  // [actCreateCompany]: () => false,
  // [actCreateCompanyError]: () => false,

  [actGetUserCompanyRequest]: () => null,
  // [actGetUserCompany]: () => false,
  // [actGetUserCompanyError]: () => false,


  [actUpdateCompanyContactsRequest]: () => null,
  [actUpdateCompanyContacts]: () => 'UpdateCompany',
  // [actUpdateCompanyContactsError]: () => false,

  [actUpdateCompanyStatusRequest]: () => null,
  // [actUpdateCompanyStatus]: () => false,
  // [actUpdateCompanyStatusError]: () => false,

  [actUpdateCompanyRequest]: () => null,
  [actUpdateCompany]: () => 'UpdateCompany',
  // [actUpdateCompanyError]: () => false,

  [actUpdateCompanyPhotoRequest]: () => null,
  // [actUpdateCompanyPhoto]: () => false,
  // [actUpdateCompanyPhotoError]: () => false,

  [actUpdateCompanyLogoRequest]: () => null,
  // [actUpdateCompanyLogo]: () => false,
  // [actUpdateCompanyLogoError]: () => false,

  [actGetUserCompanyPhotoRequest]: () => null,
  // [actGetUserCompanyPhoto]: () => false,
  // [actGetUserCompanyPhotoError]: () => false,


  [actDeleteCompanyPhotoRequest]: () => null,
  // [actDeleteCompanyPhoto]: () => false,
  // [actDeleteCompanyPhotoError]: () => false,

  [actDeleteCompanyRequest]: () => null,
  // [actDeleteCompany]: () => false,
  // [actDeleteCompanyError]: () => false,

  // ------------------------------------------- Юзер - Новости:
  [actCreateNewsRequest]: () => null,
  // [actCreateNews]: () => false,
  // [actCreateNewsError]: () => false,

  [actGetUserNewsRequest]: () => null,
  // [actGetUserNews]: () => false,
  // [actGetUserNewsError]: () => false,

  [actUpdateUserNewsRequest]: () => null,
  // [actUpdateUserNews]: () => false,
  // [actUpdateUserNewsError]: () => false,

  [actUpdateUserNewsPhotoRequest]: () => null,
  // [actUpdateUserNewsPhoto]: () => false,
  // [actUpdateUserNewsPhotoError]: () => false,

  [actUpdateUserNewsStatusAllRequest]: () => null,
  // [actUpdateUserNewsStatusAll]: () => false,
  // [actUpdateUserNewsStatusAllError]: () => false,

  [actUpdateUserNewsStatusSuperRequest]: () => null,
  // [actUpdateUserNewsStatusSuper]: () => false,
  // [actUpdateUserNewsStatusSuperError]: () => false,


  [actDeleteNewsRequest]: () => null,
  // [actDeleteNews]: () => false,
  // [actDeleteNewsError]: () => false,

}
)


export {successReducer};

