
// ----------------------------------------------------Общие-селекторы:
const selLoadingStatus = state => state.loading;
const selError = state => state.error;
const selSuccess = state => state.success;

// ----------------------------------------------------Авторизация-селектор:
const selAuthorization = state => state.isAuthorized;
const selUserAuth = state => state.userAuth;

// ----------------------------------------------------USER-селектор:
const selUserCompany = state => state.user.company;
const selUserCompanyPhoto = state => state.user.photo;
const selUserNews = state => state.user.news;
const selUserFavoriteCompany = state => state.user.favoriteCompany;

// ----------------------------------------------------USER-AUTH-селектор:
const selUserFavorite = state => state.userAuth.favorite;
const selUserId = state => state.userAuth._id;

// ----------------------------------------------------Общие-селекторы-Компании:
const selCompanyDevelopmentPagination = state => state.companies.development;
const selCompanyConstructionPagination = state => state.companies.construction;
const selCompanyDesignPagination = state => state.companies.design;
const selCompanyDevelopment = state => state.companies.development.company;
const selCompanyConstruction = state => state.companies.construction.company;
const selCompanyDesign = state => state.companies.design.company;
const selCompanyCurrent = state => state.currentCompany.company;
const selCompanyCurrentPhoto = state => state.currentCompany.photo;
const selCompanyCurrentNews = state => state.currentCompany.news;

// ----------------------------------------------------NEWS-селектор:
// selNewsGlobal - Все Новости (пагинация = ) с внешнего API:
const selNewsGlobal = state => state.news.newsGlobal
// selNewsLocal - Все Активные/Проверенные/Публичные новости (пагинация = 50):
const selNewsLocal = state => state.news.newsLocal
// selNewsLocalCurrent - Текущая новость выбранная по ее Айди:
const selNewsLocalCurrent = state => state.news.newsCurrent
// selNewsPublic - Все существующие новости (пагинация = 50) со статусом "isPublic":
const selNewsPublic = state => state.news.newsPublic


// // -------------> ПРИМЕР - Мемоизированный селектор (Для правильного рендера отфильтрованного списка)
// const selRenderFilter = createSelector(
//   [selContacts, selFilterValue],
//   (contacts, filter) => {
//     const normalizeValue = filter.toLowerCase();
//     const findContact = contacts.filter(item =>
//       item.name.toLowerCase().includes(normalizeValue),
//     );
//     return findContact;
//   },
// );

// // const selRenderFilter = state => {
// //   const filterValue = selFilterValue(state);
// //   const contacts = selContacts(state);
// //   const normalizeValue = filterValue.toLowerCase();
// //   const findContact = contacts.filter(item =>
// //     item.name.toLowerCase().includes(normalizeValue),
// //   );
// //   return findContact;
// // };

export {
  selAuthorization,
  selUserAuth,
  selLoadingStatus,
  selUserCompany,
  selUserCompanyPhoto,
  selUserNews,
  selUserFavoriteCompany,
  selUserFavorite,
  selUserId,
  selError,
  selSuccess,
  selCompanyDevelopment,
  selCompanyDevelopmentPagination,
  selCompanyConstruction,
  selCompanyConstructionPagination,
  selCompanyDesign,
  selCompanyDesignPagination,
  selCompanyCurrent,
  selCompanyCurrentPhoto,
  selCompanyCurrentNews,
  selNewsGlobal,
  selNewsLocal,
  selNewsLocalCurrent,
  selNewsPublic,
};

// export {
//     selAuthorization,
//     selUser,
//     selContacts,
//     selFilterValue,
//     selLoadingStatus,
//     selRenderFilter,
//   };
