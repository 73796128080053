import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  // actGetCompany,
  // actGetCompanyError,
  // actGetCompanyRequest,
  
  actGetCompanyDeveloper,
  // actGetCompanyDeveloperError,
  // actGetCompanyDeveloperRequest,

  actGetCompanyBuilder,
  // actGetCompanyBuilderError,
  // actGetCompanyBuilderRequest,

  actGetCompanyDesigner,
  // actGetCompanyDesignerError,
  // actGetCompanyDesignerRequest,

  // actFilterCompany,
  // actFilterCompanyError,
  // actFilterCompanyRequest,

} from '../actions/company';

// --------------------------------------------------> Companies State

const development = createReducer([],{
  [actGetCompanyDeveloper]: (state, action) => action.payload.data,
  },
);

const construction = createReducer([],{
  [actGetCompanyBuilder]: (state, action) => action.payload.data,
},
);

const design = createReducer([],{
  [actGetCompanyDesigner]: (state, action) => action.payload.data,
},
);

// const filter = createReducer([],{
//   [actFilterCompany]: (state, action) => action.payload.data,
// },
// );

export default combineReducers({
  development, 
  construction, 
  design, 
  // filter
});
