import { createReducer } from '@reduxjs/toolkit';

import {
  actRegister,
  actRegisterError,
  actRegisterRequest,

  actLogin,
  actLoginError,
  actLoginRequest,

  actLogout,
  actLogoutError,
  actLogoutRequest,
  
  // actCurrentUser,
  // actCurrentUserError,
  // actCurrentUserRequest,

  actUpdateUser,
  actUpdateUserError,
  actUpdateUserRequest,

  actUpdateUserEmail,
  actUpdateUserEmailError,
  actUpdateUserEmailRequest,

  actUpdateUserPass,
  actUpdateUserPassError,
  actUpdateUserPassRequest,

  actForgotUserPass,
  actForgotUserPassError,
  actForgotUserPassRequest,

  actUserSendEmail,
  actUserSendEmailError,
  actUserSendEmailRequest,

  actDeleteUser,
  actDeleteUserError,
  actDeleteUserRequest,
} from '../actions/userAuth';

import {
  actCreateCompany,
  actCreateCompanyError,
  actCreateCompanyRequest,

  actGetUserCompany,
  actGetUserCompanyError,
  actGetUserCompanyRequest,

  // actUpdateCompanyContacts,
  // actUpdateCompanyContactsError,
  // actUpdateCompanyContactsRequest,

  actDeleteCompanyPhoto,
  actDeleteCompanyPhotoError,
  actDeleteCompanyPhotoRequest,

  actDeleteCompany,
  actDeleteCompanyError,
  actDeleteCompanyRequest,

  // actUpdateCompanyStatus,
  // actUpdateCompanyStatusError,
  // actUpdateCompanyStatusRequest,

  actUpdateCompany,
  actUpdateCompanyError,
  actUpdateCompanyRequest,

  actUpdateCompanyPhoto,
  actUpdateCompanyPhotoError,
  actUpdateCompanyPhotoRequest,

  actUpdateCompanyLogo,
  actUpdateCompanyLogoError,
  actUpdateCompanyLogoRequest,

  // actGetUserCompanyPhoto,
  actGetUserCompanyPhotoError,
  // actGetUserCompanyPhotoRequest,
} from '../actions/userCompany';

import {
  actCreateNews,
  actCreateNewsError,
  actCreateNewsRequest,

  actGetUserNews,
  actGetUserNewsError,
  actGetUserNewsRequest,

  actUpdateUserNews,
  actUpdateUserNewsError,
  actUpdateUserNewsRequest,

  actUpdateUserNewsPhoto,
  actUpdateUserNewsPhotoError,
  actUpdateUserNewsPhotoRequest,
  
  actUpdateUserNewsStatusAll,
  actUpdateUserNewsStatusAllError,
  actUpdateUserNewsStatusAllRequest,

  actUpdateUserNewsStatusSuper,
  actUpdateUserNewsStatusSuperError,
  actUpdateUserNewsStatusSuperRequest,

  actDeleteNews,
  actDeleteNewsError,
  actDeleteNewsRequest,
} from '../actions/userNews';

import {
actGetCompany,
actGetCompanyError,
actGetCompanyRequest,

actGetCompanyCurrent,
actGetCompanyCurrentError,
actGetCompanyCurrentRequest,
  
actGetCompanyDeveloper,
actGetCompanyDeveloperError,
actGetCompanyDeveloperRequest,

actGetCompanyBuilder,
actGetCompanyBuilderError,
actGetCompanyBuilderRequest,

actGetCompanyDesigner,
actGetCompanyDesignerError,
actGetCompanyDesignerRequest,

actGetCompanyFavorite,
actGetCompanyFavoriteError,
actGetCompanyFavoriteRequest,

// actFilterCompany,
// actFilterCompanyError,
// actFilterCompanyRequest,

} from '../actions/company';

import { 
  // actNewsGlobal, 
  // actNewsGlobalError, 
  // actNewsGlobalRequest, 

  actNewsLocal, 
  actNewsLocalError, 
  actNewsLocalRequest,

  actNewsLocalCurrent, 
  actNewsLocalCurrentError, 
  actNewsLocalCurrentRequest,

  actNewsPublic, 
  actNewsPublicError,
  actNewsPublicRequest,
} from '../actions/news';

// --------------------------------------------------> Loading
const loadingReducer = createReducer(false, {

  // ------------------------------------------- Список Компаний:
  [actGetCompanyRequest]: () => true,
  [actGetCompany]: () => false,
  [actGetCompanyError]: () => false,

  [actGetCompanyCurrentRequest]: () => true,
  [actGetCompanyCurrent]: () => false,
  [actGetCompanyCurrentError]: () => false,

  [actGetCompanyDeveloperRequest]: () => true,
  [actGetCompanyDeveloper]: () => false,
  [actGetCompanyDeveloperError]: () => false,

  [actGetCompanyBuilderRequest]: () => true,
  [actGetCompanyBuilder]: () => false,
  [actGetCompanyBuilderError]: () => false,

  [actGetCompanyDesignerRequest]: () => true,
  [actGetCompanyDesigner]: () => false,
  [actGetCompanyDesignerError]: () => false,

  [actGetCompanyFavoriteRequest]: () => true,
  [actGetCompanyFavorite]: () => false,
  [actGetCompanyFavoriteError]: () => false,

  // [actFilterCompanyRequest]: () => true,
  // [actFilterCompany]: () => false,
  // [actFilterCompanyError]: () => false,

  // ------------------------------------------- Список Новостей:
  // actNewsGlobal - выключили потому что может пойти запрос, а ответ не прийти и бесконечная будет загрузка
  // [actNewsGlobalRequest]: () => true,
  // [actNewsGlobal]: () => false,
  // [actNewsGlobalError]: () => false,

  [actNewsLocalRequest]: () => true,
  [actNewsLocal]: () => false,
  [actNewsLocalError]: () => false,

  [actNewsLocalCurrentRequest]: () => true,
  [actNewsLocalCurrent]: () => false,
  [actNewsLocalCurrentError]: () => false,

  [actNewsPublicRequest]: () => true,
  [actNewsPublic]: () => false,
  [actNewsPublicError]: () => false,

  // ------------------------------------------- Юзер - Авторизация:
  [actRegisterRequest]: () => true,
  [actRegister]: () => false,
  [actRegisterError]: () => false,

  [actLoginRequest]: () => true,
  [actLogin]: () => false,
  [actLoginError]: () => false,

  [actLogoutRequest]: () => true,
  [actLogout]: () => false,
  [actLogoutError]: () => false,

  [actUpdateUserRequest]: () => true,
  [actUpdateUser]: () => false,
  [actUpdateUserError]: () => false,

  [actUpdateUserEmailRequest]: () => true,
  [actUpdateUserEmail]: () => false,
  [actUpdateUserEmailError]: () => false,

  [actUpdateUserPassRequest]: () => true,
  [actUpdateUserPass]: () => false,
  [actUpdateUserPassError]: () => false,

  [actForgotUserPassRequest]: () => true,
  [actForgotUserPass]: () => false,
  [actForgotUserPassError]: () => false,

  [actUserSendEmailRequest]: () => true,
  [actUserSendEmail]: () => false,
  [actUserSendEmailError]: () => false,

  [actDeleteUserRequest]: () => true,
  [actDeleteUser]: () => false,
  [actDeleteUserError]: () => false,


  // ------------------------------------------- Юзер - Компания:
  [actCreateCompanyRequest]: () => true,
  [actCreateCompany]: () => false,
  [actCreateCompanyError]: () => false,

  [actGetUserCompanyRequest]: () => true,
  [actGetUserCompany]: () => false,
  [actGetUserCompanyError]: () => false,


  // TODO: отключили что раньше времени Загрузчик не переходил в статус false, что бы футер не уезжал вверх
  // при создании Компании.
  // Хотя если он понадобится, то просто в 1-MyCompany.js нужно включить п.2
  // [actUpdateCompanyContactsRequest]: () => true,
  // [actUpdateCompanyContacts]: () => false,
  // [actUpdateCompanyContactsError]: () => false,

  // Включает не вовремя индикатор Загрузки, по этому выключили:
  // [actUpdateCompanyStatusRequest]: () => true,
  // [actUpdateCompanyStatus]: () => false,
  // [actUpdateCompanyStatusError]: () => false,

  [actUpdateCompanyRequest]: () => true,
  [actUpdateCompany]: () => false,
  [actUpdateCompanyError]: () => false,

  [actUpdateCompanyPhotoRequest]: () => true,
  [actUpdateCompanyPhoto]: () => false,
  [actUpdateCompanyPhotoError]: () => false,

  [actUpdateCompanyLogoRequest]: () => true,
  [actUpdateCompanyLogo]: () => false,
  [actUpdateCompanyLogoError]: () => false,

  // TODO: Убрали отключение статуса загрузки, потому что на странице "Моя Компания"
  // приходит два ответа с бека - это фото и вся инфо о Компании. И ответ по фото слишком рано
  // отключал загрузчик, и как баг футер уезжал вверх
  // [actGetUserCompanyPhotoRequest]: () => true,
  // [actGetUserCompanyPhoto]: () => false,
  [actGetUserCompanyPhotoError]: () => false,


  [actDeleteCompanyPhotoRequest]: () => true,
  [actDeleteCompanyPhoto]: () => false,
  [actDeleteCompanyPhotoError]: () => false,

  [actDeleteCompanyRequest]: () => true,
  [actDeleteCompany]: () => false,
  [actDeleteCompanyError]: () => false,

  // ------------------------------------------- Юзер - Новости:
  [actCreateNewsRequest]: () => true,
  [actCreateNews]: () => false,
  [actCreateNewsError]: () => false,

  [actGetUserNewsRequest]: () => true,
  [actGetUserNews]: () => false,
  [actGetUserNewsError]: () => false,

  [actUpdateUserNewsRequest]: () => true,
  [actUpdateUserNews]: () => false,
  [actUpdateUserNewsError]: () => false,

  [actUpdateUserNewsPhotoRequest]: () => true,
  [actUpdateUserNewsPhoto]: () => false,
  [actUpdateUserNewsPhotoError]: () => false,

  [actUpdateUserNewsStatusAllRequest]: () => true,
  [actUpdateUserNewsStatusAll]: () => false,
  [actUpdateUserNewsStatusAllError]: () => false,

  [actUpdateUserNewsStatusSuperRequest]: () => true,
  [actUpdateUserNewsStatusSuper]: () => false,
  [actUpdateUserNewsStatusSuperError]: () => false,



  [actDeleteNewsRequest]: () => true,
  [actDeleteNews]: () => false,
  [actDeleteNewsError]: () => false,


  // [actGetListRequest]: () => true,
  // [actGetList]: () => false,
  // [actGetListError]: () => false,

  // [actAddItemRequest]: () => true,
  // [actAddItem]: () => false,
  // [actAddItemError]: () => false,

  // [actDeleteItemRequest]: () => true,
  // [actDeleteItem]: () => false,
  // [actDeleteItemError]: () => false,

  // [actFilterListRequest]: () => true,
  // [actFilterList]: () => false,
  // [actFilterListError]: () => false,
}
)


export {loadingReducer};

