import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

// Импорт из библиотеки:
import { Box } from '@mui/system';

// Импорт моих компонентов:
import { ButtonStyleNav } from '../../components/Elements/Button/ButtonStyle';

// Импорт медиазапросов из библиотеки:
import useMediaQuery from '@mui/material/useMediaQuery';

// Обнуление State:
import { actDefaultCompanyCurrent, actDefaultError } from '../../redux/actions/helpers';

// Импорт селекторов:
import { selCompanyCurrent } from '../../redux/selectors/selectorMain';

// Импорт путей:
import route from '../../routes/routes'

// Импорт стилей:
import '../../App.css';
import style from './Navigation.module.css';


export default function Navigation() {
  const mobile = useMediaQuery('(max-width:767px)');
  // const tablet = useMediaQuery('(max-width:1199px)');
  // const desktop = useMediaQuery('(min-width:1200px)');

  // Dispatch:
  const dispatch = useDispatch();

  // Selectors:
  const companyCurrent = useSelector(selCompanyCurrent)

  // Переключатель кнопки для изменения Активного и Не активного класа:
  const [button1, setButton1] = useState(true);
  const [button2, setButton2] = useState(true);
  const [button3, setButton3] = useState(true);
  const [button4, setButton4] = useState(true);


  const fnRequest = (name) => {
    // 1) При нажатии на Кнопки меняем ей стиль(клас) что бы кнопка не теряла цвет при нажатии в другомм месте: 
    switch (name) {
      case 'Main':
        setButton1(false);
        setButton2(true);
        setButton3(true);
        setButton4(true);
        dispatch(actDefaultError()) // Сделано для того: если пришла ошибка что бы она обнулялась при каждом переходе в др.раздел
        break;
      case 'Development':
        setButton1(true);
        setButton2(false);
        setButton3(true);
        setButton4(true);
        dispatch(actDefaultError())
        break;
      case 'Construction':
        setButton1(true);
        setButton2(true);
        setButton3(false);
        setButton4(true);
        dispatch(actDefaultError())
        break;
      case 'Design':
        setButton1(true);
        setButton2(true);
        setButton3(true);
        setButton4(false);
        dispatch(actDefaultError())
        break;
      default:
          console.log(`Тип поля - ${name} - не обрабатывается`)
          }

    // 2) При нажатии на кнопки навигации, каждый раз сбрасываем в State значение Текущей Компании, если Компания там есть,
    // что бы небыло визуального дефекта предыдущей компании
    if (companyCurrent) {
      dispatch(actDefaultCompanyCurrent())
      return
    }

    return
  }

  return (
    // <section className="App-navigation">
      // {/* TODO: убрать paddingBottom: '0px'  - если будут кнопки навигации доплнительные*/}
      <Box sx={{paddingY: {sm: '0px', lg: '0px'}}}>
        {/* =============================================> 1) Умова рендеру для ПЛАНШЕТУ та вище ====================================== */}
        {!mobile ? (
            <nav className={style.flexbox_nav}>
              <NavLink to={route.home} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: { sm: '14px', lg: '16px'},}} onClick={() => fnRequest('Main')}>
                  Головна</ButtonStyleNav>
              </NavLink>
              {/* TODO: link? */}
              <NavLink to={route.development} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {sm: '14px', lg: '16px'},}} onClick={() => fnRequest('Development')}>
                  Девелопмент</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.building} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {sm: '14px', lg: '16px'},}} onClick={() => fnRequest('Construction')}>
                  Будівництво</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.design} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {sm: '14px', lg: '16px'},}} onClick={() => fnRequest('Design')}>
                  Проектування</ButtonStyleNav>
              </NavLink>
            </nav>
            // {/* TODO: Кнопки не активные навигации - отключили временно */}
            // <nav className={style.flexbox_nav} style={{ marginTop: '15px' }}>
            //   <ButtonNavigation title="Постачання" route={route.supply} status={true} />
            //   <ButtonNavigation title="Події" route={route.event} status={true} />
            //   <ButtonNavigation title="Робота" route={route.job} status={true} />
            // </nav>
        ) : null}
        {/* =============================================> 2) Умова рендеру для МОБІЛЬНОЇ версії ========================================= */}
        {mobile ? (
          <div>
            <nav className={style.flexbox_nav}>
            <NavLink to={route.home} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {xs: '14px'},}} onClick={() => fnRequest('Main')}>
                  Головна</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.development} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {xs: '14px'},}} onClick={() => fnRequest('Development')}>
                  Девелопмент</ButtonStyleNav>
              </NavLink>
            </nav>
            <nav className={style.flexbox_nav} style={{ marginTop: '0px' }}>
              <NavLink to={route.building} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {xs: '14px'},}} onClick={() => fnRequest('Construction')}>
                  Будівництво</ButtonStyleNav>
              </NavLink>
              <NavLink to={route.design} className="link">
                <ButtonStyleNav variant="text" sx={{fontSize: {xs: '14px'},}} onClick={() => fnRequest('Design')}>
                  Проектування</ButtonStyleNav>
              </NavLink>
            </nav>
          </div>
        ) : null}
      </Box>
    // </section>
  );
}
