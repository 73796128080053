import React, { Suspense, lazy, useEffect, } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import { Route } from 'react-router-dom';
import { useLocation, } from 'react-router-dom';
// import React, { Suspense, lazy, useEffect, Children } from 'react';
// import { connect, useDispatch, useSelector } from 'react-redux';
// import { useParams, useLocation, useRouteMatch  } from 'react-router-dom';

// Импорт запросов на бэк:
import { apiGetCurrentUser} from './api/api-userAuth';

import route from './routes/routes';
import PrivateRoute from './routes/PrivateRoute';
import PublickRoute from './routes/PublickRoute';

// Импорт медиазапросов из библиотеки:
import useMediaQuery from '@mui/material/useMediaQuery';

// Иморт секций:
import Header from './cections/section-Header/Header';
import Message from './cections/section-Message/Message';
import Footer from './cections/section-Footer/Footer';

// Импорт Загрузчика:
import Loading from './components/Elements/Loading/Loading';

// Импорт стилей:
import './App.css';
import { COLOR_TEXT_LOGO } from './helpers/constants';


const PageHome = lazy(() =>
  import('./pages/Home/PageHome.js' /*webpackChunkName: "PageHome"*/),
);
const PageDevelopment = lazy(() =>
  import(
    './pages/Development/PageDevelopment' /*webpackChunkName: "PageDevelopment"*/
  ),
);
const PageConstruction = lazy(() =>
  import('./pages/Construction/PageConstruction' /*webpackChunkName: "PageConstruction"*/),
);
const PageDesign = lazy(() =>
  import('./pages/Design/PageDesign' /*webpackChunkName: "PageDesign"*/),
);
const LoginPage = lazy(() =>
  import('./pages/Login/login' /*webpackChunkName: "LoginPage"*/),
);
const RegisterPage = lazy(() =>
  import('./pages/Register/register' /*webpackChunkName: "RegisterPage"*/),
);

const UserPage = lazy(() =>
  import('./pages/User/PageUser' /*webpackChunkName: "PageUser"*/),
);

const PageCompany = lazy(() =>
  import('./pages/Company/PageCompany' /*webpackChunkName: "PageCompany"*/),
);

const PageNews = lazy(() =>
  import('./pages/News/PageNews' /*webpackChunkName: "PageNews"*/),
);


function App() {
  // Все запросы перенесены на соответветсвующие страницы
  const dispatch = useDispatch();
  const mobile = useMediaQuery('(max-width:767px)');
  const tablet = useMediaQuery('(max-width:1199px)');
  const desktop = useMediaQuery('(min-width:1200px)');

  // TODO: ВНИМАНИЕ: В Реакте Компоненты обновляются с  начиная с нижнего Компонента, а потом уже APP
  // (эт овлияет на последовательность загрузки инфо и запросов на бэк!)
  useEffect(() => {
    dispatch(apiGetCurrentUser())
    // Запросы за Новостями делаются на странице HomePage:
    // dispatch(apiGetNewsGlobal());
    // dispatch(apiGetNewsLocal());
  });


// TODO: Что бы было сообщение о тот что "Такой страницы не существует" была написана следующая проверка:
// 1) Находим наш путь:
let location = useLocation();
let userRoute = location.pathname
// console.log("🚀 ~ file: PageCompany.js ~ line 28 ~ PageCompany ~ location ", location )

// 2) Так как динамические пути у нас записаны route.js как '/news/:id', а приходит к нам '/news/1243254' мы 
// обрезаем нашу строку до нужного символа и делаем потом проверку (обрезаем нашу строку для /news/1234785 и получим /news):
const newsString = userRoute.slice(0, 6)
// console.log("🚀 ~ file: App.js ~ line 79 ~ App ~ newsString", newsString)
// Обрезаем нашу строку для /development/:id (получим /development)
const developmentString = userRoute.slice(0, 13)
// Обрезаем нашу строку для /building/:id (получим /building)
const buildingString = userRoute.slice(0, 10)
// Обрезаем нашу строку для /design/:id (получим /design)
const designString = userRoute.slice(0, 8)

// 3) Создали массив со всеми сущестующими путями нашими
const arrayOfRoutes = [
  route.home, route.homeName, 
  route.news, 
  route.register, route.login, route.user, 
  route.building, route.development, route.design, 
  // route.developmentCompany, route.buildingCompany, route.designCompany, route.newsOne,   // динамические роуты не учитываем так как у нас /:id а в строке приходит полностью айдишник и как то приравнять его неполучается
]
// console.log("🚀 ~ file: App.js ~ line 97 ~ App ~ arrayOfRoutes", arrayOfRoutes)

// 3) Делаем проверку совпадает ли наш путь с существующими, если нет то рендерим ниже сообщение
// 3.1) Проверка если путь не динамический:
let checkRoute = arrayOfRoutes.includes(userRoute)
// 3.2) Проверка если путь динамический:
if ( !checkRoute && (newsString === '/news/' || developmentString === '/development/' || buildingString === '/building/' || designString === '/design/')) {
  checkRoute = true
}

  return (
    <div className="App">
      {/* <div className="App-background-img"> */}
      <Suspense
        // fallback={<h3 style={{ marginTop: 200, color: '#ff6b6b' }}>Loading...</h3>}
        fallback={<Loading size={200} color={COLOR_TEXT_LOGO}/>}
      >
        <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
          {desktop && (<div className='Banner_desktop'/>)}
          {tablet && !mobile && (<div className='Banner_tablet'/>)}
          {mobile && (<div className='Banner_mobile'/>)}
        </div>
        <Header></Header>
        {/* Проверка "Существует ли путь указанный пользователем" */}
        {checkRoute ? null : (<Message/>)}
        <Switch>
        <Route exact path={route.home}>
            <PageHome />
        </Route>
        <Route exact path={route.homeName}>
            <PageHome />
        </Route>
        <Route exact path={route.news}>
            <PageHome />
        </Route>
          {/* Если пользователь не ввошел то он попадает на регистрацию или логинизацию, если ввошел то на др.страницу */}
          {/* ================ Публичный путь - /registr/ ================================== */}
          <PublickRoute path={route.register} redirectTo={route.building} restricted>
            <RegisterPage />
          </PublickRoute>
          {/* ================ Публичный путь - /login/ ==================================== */}
          <PublickRoute path={route.login} redirectTo={route.user} restricted>
            <LoginPage />
          </PublickRoute>
          {/* ================ Приватный путь - /user/ ===================================== */}
          <PrivateRoute path={route.user} redirectTo={route.login}>
            <UserPage />
          </PrivateRoute>
          {/* ================ Публичный путь - /development/ ============================== */}
          <PublickRoute  exact path={route.development}
            // ! exact - очень важен без него не работают динамические адреса
            // redirectTo={route.home}
            >
            <PageDevelopment />
          </PublickRoute>
          {/* ================ Публичный путь - /building/ ================================ */}
          <PublickRoute exact path={route.building}
            // ! exact - очень важен без него не работают динамические адреса
            // redirectTo={route.home}
            >
            <PageConstruction />
          </PublickRoute>
          {/* ================ Публичный путь - /design/ ================================== */}
          <PublickRoute exact path={route.design}
            // ! exact - очень важен без него не работают динамические адреса
            // redirectTo={route.home}
            >
            <PageDesign />
          </PublickRoute>
          {/* ================ Публичный путь - /development/:id - Конкретная Компания === */}
            <PublickRoute
            path={route.developmentCompany}
            // redirectTo={route.home}
            >
            <PageCompany />
          </PublickRoute>
          {/* ================ Публичный путь - /building/:id - Конкретная Компания ====== */}
          <PublickRoute
            path={route.buildingCompany}
            // redirectTo={route.home}
            >
            <PageCompany />
          </PublickRoute>
          {/* ================ Публичный путь - /design/:id - Конкретная Компания ======= */}
          <PublickRoute
            path={route.designCompany}
            // redirectTo={route.home}
            >
            <PageCompany />
          </PublickRoute>
          {/* ================ Публичный путь - /news/:id - стр.Текущие НОВОСТИ ======= */}
          <PublickRoute
            path={route.newsOne}
            >
            <PageNews/>
          </PublickRoute>
          {/* ================ Публичный путь - /news/ - стр. ВСЕ НОВОСТИ (по факту только вручную на этот адрес можно зайти) ======= */}
          <PublickRoute
            path={route.news}
            // redirectTo={route.home}
            >
            <PageNews/>
          </PublickRoute>
        </Switch>
        {/* <PreFooter/> */}
        <Footer />
      </Suspense>
      {/* </div> */}
    </div>
  );
}

export default App;
