import { createAction } from '@reduxjs/toolkit';

// ------------------------------------------------------> Создание Компании при Первом Запуске:
const actCreateCompany = createAction('@CreateCompany/SUCCSESS');
const actCreateCompanyError = createAction('@CreateCompany/ERROR');
const actCreateCompanyRequest = createAction('@CreateCompany/REQUEST');

// ------------------------------------------------------> Получаем инфо о нашей Компании:
const actGetUserCompany = createAction('@GetUserCompany/SUCCSESS');
const actGetUserCompanyError = createAction('@GetUserCompany/ERROR');
const actGetUserCompanyRequest = createAction('@GetUserCompany/REQUEST');

// ------------------------------------------------------> Получаем все фото нашей Компании:
const actGetUserCompanyPhoto = createAction('@GetUserCompanyPhoto/SUCCSESS');
const actGetUserCompanyPhotoError = createAction('@GetUserCompanyPhoto/ERROR');
const actGetUserCompanyPhotoRequest = createAction('@GetUserCompanyPhoto/REQUEST');

// ------------------------------------------------------> Обновление контактов Компании:
const actUpdateCompanyContacts = createAction('@UpdateCompanyContacts/SUCCSESS');
const actUpdateCompanyContactsError = createAction('@UpdateCompanyContacts/ERROR');
const actUpdateCompanyContactsRequest = createAction('@UpdateCompanyContacts/REQUEST');

// ------------------------------------------------------> Обновление статуса Компании:
const actUpdateCompanyStatus = createAction('@UpdateCompanyStatus/SUCCSESS');
const actUpdateCompanyStatusError = createAction('@UpdateCompanyStatus/ERROR');
const actUpdateCompanyStatusRequest = createAction('@UpdateCompanyStatus/REQUEST');

// ------------------------------------------------------> Обновление Основной инфо-и о Компании:
const actUpdateCompany = createAction('@UpdateCompany/SUCCSESS');
const actUpdateCompanyError = createAction('@UpdateCompany/ERROR');
const actUpdateCompanyRequest = createAction('@UpdateCompany/REQUEST');

// ------------------------------------------------------> Обновление Основной инфо-и о Компании:
const actUpdateCompanyPhoto = createAction('@UpdateCompanyPhoto/SUCCSESS');
const actUpdateCompanyPhotoError = createAction('@UpdateCompanyPhoto/ERROR')
const actUpdateCompanyPhotoRequest = createAction('@UpdateCompanyPhoto/REQUEST');

// ------------------------------------------------------> Обновление Лого Компании:
const actUpdateCompanyLogo = createAction('@UpdateCompanyLogo/SUCCSESS');
const actUpdateCompanyLogoError = createAction('@UpdateCompanyLogo/ERROR')
const actUpdateCompanyLogoRequest = createAction('@UpdateCompanyLogo/REQUEST');

// ------------------------------------------------------> DELETE ФОТО Компании:
const actDeleteCompanyPhoto = createAction('@DeleteCompanyPhoto/SUCCSESS');
const actDeleteCompanyPhotoError = createAction('@DeleteCompanyPhoto/ERROR');
const actDeleteCompanyPhotoRequest = createAction('@DeleteCompanyPhoto/REQUEST');

// ------------------------------------------------------> DELETE Компанию:
const actDeleteCompany = createAction('@DeleteCompany/SUCCSESS');
const actDeleteCompanyError = createAction('@DeleteCompany/ERROR');
const actDeleteCompanyRequest = createAction('@UDeleteCompany/REQUEST');

export {
  actCreateCompany,
  actCreateCompanyError,
  actCreateCompanyRequest,
  actUpdateCompanyContacts,
  actUpdateCompanyContactsError,
  actUpdateCompanyContactsRequest,
  actGetUserCompany,
  actGetUserCompanyError,
  actGetUserCompanyRequest,

  actGetUserCompanyPhoto,
  actGetUserCompanyPhotoError,
  actGetUserCompanyPhotoRequest,

  actUpdateCompanyStatus,
  actUpdateCompanyStatusError,
  actUpdateCompanyStatusRequest,
  
  actUpdateCompany,
  actUpdateCompanyError,
  actUpdateCompanyRequest,

  actUpdateCompanyPhoto,
  actUpdateCompanyPhotoError,
  actUpdateCompanyPhotoRequest,

  actUpdateCompanyLogo,
  actUpdateCompanyLogoError,
  actUpdateCompanyLogoRequest,

  actDeleteCompanyPhoto,
  actDeleteCompanyPhotoError,
  actDeleteCompanyPhotoRequest,

  actDeleteCompany,
  actDeleteCompanyError,
  actDeleteCompanyRequest,
};