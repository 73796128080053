import { createReducer } from '@reduxjs/toolkit';

import {
  actRegister,
  actRegisterError,
  actRegisterRequest,

  actLogin,
  actLoginError,
  actLoginRequest,

  actLogout,
  actLogoutError,
  actLogoutRequest,

  actCurrentUser,
  actCurrentUserError,
  actCurrentUserRequest,

  actUpdateUser,
  actUpdateUserError,
  actUpdateUserRequest,

  actUpdateUserEmail,
  actUpdateUserEmailError,
  actUpdateUserEmailRequest,

  actUpdateUserPass,
  actUpdateUserPassError,
  actUpdateUserPassRequest,

  actForgotUserPass,
  actForgotUserPassError,
  actForgotUserPassRequest,

  actUserSendEmail,
  actUserSendEmailError,
  actUserSendEmailRequest,

  actDeleteUser,
  actDeleteUserError,
  actDeleteUserRequest,
} from '../actions/userAuth';

import {
  actCreateCompany,
  actCreateCompanyError,
  actCreateCompanyRequest,

  actGetUserCompany,
  actGetUserCompanyError,
  actGetUserCompanyRequest,

  actUpdateCompanyContacts,
  actUpdateCompanyContactsError,
  actUpdateCompanyContactsRequest,

  actDeleteCompanyPhoto,
  actDeleteCompanyPhotoError,
  actDeleteCompanyPhotoRequest,

  actDeleteCompany,
  actDeleteCompanyError,
  actDeleteCompanyRequest,

  actUpdateCompanyStatus,
  actUpdateCompanyStatusError,
  actUpdateCompanyStatusRequest,

  actUpdateCompany,
  actUpdateCompanyError,
  actUpdateCompanyRequest,

  actUpdateCompanyPhoto,
  actUpdateCompanyPhotoError,
  actUpdateCompanyPhotoRequest,

  actUpdateCompanyLogo,
  actUpdateCompanyLogoError,
  actUpdateCompanyLogoRequest,

  actGetUserCompanyPhoto,
  actGetUserCompanyPhotoError,
  actGetUserCompanyPhotoRequest,
} from '../actions/userCompany';

import {
  actCreateNews,
  actCreateNewsError,
  actCreateNewsRequest,

  actGetUserNews,
  actGetUserNewsError,
  actGetUserNewsRequest,

  actUpdateUserNews,
  actUpdateUserNewsError,
  // actUpdateUserNewsRequest,

  actUpdateUserNewsPhoto,
  actUpdateUserNewsPhotoError,
  // actUpdateUserNewsPhotoRequest,

  actUpdateUserNewsStatusAll,
  actUpdateUserNewsStatusAllError,
  // actUpdateUserNewsStatusAllRequest,

  actUpdateUserNewsStatusSuper,
  actUpdateUserNewsStatusSuperError,
  // actUpdateUserNewsStatusSuperRequest,

  actDeleteNews,
  actDeleteNewsError,
  actDeleteNewsRequest,
} from '../actions/userNews';

import {
  actGetCompany,
  actGetCompanyError,
  actGetCompanyRequest,

  actGetCompanyCurrent,
  actGetCompanyCurrentError,
  actGetCompanyCurrentRequest,
    
  actGetCompanyDeveloper,
  actGetCompanyDeveloperError,
  actGetCompanyDeveloperRequest,

  actGetCompanyBuilder,
  actGetCompanyBuilderError,
  actGetCompanyBuilderRequest,

  actGetCompanyDesigner,
  actGetCompanyDesignerError,
  actGetCompanyDesignerRequest,

  actChangeFavorite,
  actChangeFavoriteError,
  actChangeFavoriteRequest,

  actGetCompanyFavorite,
  actGetCompanyFavoriteError,
  actGetCompanyFavoriteRequest,

  actChangeLike,
  actChangeLikeError,
  actChangeLikeRequest,
  } from '../actions/company';

  import { 
    actNewsGlobal, 
    actNewsGlobalError, 
    actNewsGlobalRequest, 

    actNewsLocal, 
    actNewsLocalError, 
    actNewsLocalRequest,

    actNewsLocalCurrent, 
    actNewsLocalCurrentError, 
    actNewsLocalCurrentRequest,
    
    actNewsPublic, 
    actNewsPublicError,
    actNewsPublicRequest,
  } from '../actions/news';


import { actDefaultError } from '../actions/helpers';


// --------------------------------------------------> State -> ERROR:


const errorReducer = createReducer(null, {


  // ----------------------------------------------------------------- Список Компаний:
  [actGetCompanyError]: (state, action) => action.payload.response.data,
  [actGetCompany]: (state, action) => null,
  [actGetCompanyRequest]: (state, action) => null,

  [actGetCompanyCurrentError]: (state, action) => action.payload.response.data,
  [actGetCompanyCurrent]: (state, action) => null,
  [actGetCompanyCurrentRequest]: (state, action) => null,

  [actGetCompanyDeveloperError]: (state, action) => action.payload.response.data,
  [actGetCompanyDeveloper]: (state, action) => null,
  [actGetCompanyDeveloperRequest]: (state, action) => null,

  [actGetCompanyBuilderError]: (state, action) => action.payload.response.data,
  [actGetCompanyBuilder]: (state, action) => null,
  [actGetCompanyBuilderRequest]: (state, action) => null,

  [actGetCompanyDesignerError]: (state, action) => action.payload.response.data,
  [actGetCompanyDesigner]: (state, action) => null,
  [actGetCompanyDesignerRequest]: (state, action) => null,

  [actChangeFavoriteError]: (state, action) => action.payload.response.data,
  [actChangeFavorite]: (state, action) => null,
  [actChangeFavoriteRequest]: (state, action) => null,

  [actGetCompanyFavoriteError]: (state, action) => action.payload.response.data,
  [actGetCompanyFavorite]: (state, action) => null,
  [actGetCompanyFavoriteRequest]: (state, action) => null,

  [actChangeLikeError]: (state, action) => action.payload.response.data,
  [actChangeLike]: (state, action) => null,
  [actChangeLikeRequest]: (state, action) => null,

  // ------------------------------------------- Список Новостей:

  [actNewsGlobalError]: (state, action) => action.payload.response.data,
  [actNewsGlobal]: (state, action) => null,
  [actNewsGlobalRequest]: (state, action) => null,

  [actNewsLocalError]: (state, action) => action.payload.response.data,
  [actNewsLocal]: (state, action) => null,
  [actNewsLocalRequest]: (state, action) => null,

  [actNewsLocalCurrentError]: (state, action) => action.payload.response.data,
  [actNewsLocalCurrent]: (state, action) => null,
  [actNewsLocalCurrentRequest]: (state, action) => null,

  [actNewsPublicError]: (state, action) => action.payload.response.data,
  [actNewsPublic]: (state, action) => null,
  [actNewsPublicRequest]: (state, action) => null,

  // ----------------------------------------------------------------- Юзер - АВТОРИЗАЦИЯ:
  [actRegisterError]: (state, action) => action.payload.response.data,
  [actRegister]: (state, action) => null,
  [actRegisterRequest]: (state, action) => null,

  [actLoginError]: (state, action) => action.payload.response.data,
  [actLogin]: (state, action) => null,
  [actLoginRequest]: (state, action) => null,

  [actLogoutError]: (state, action) => action.payload.response.data,
  [actLogout]: (state, action) => null,
  [actLogoutRequest]: (state, action) => null,

  [actCurrentUserError]: (state, action) => action.payload.response.data,
  [actCurrentUser]: (state, action) => null,
  [actCurrentUserRequest]: (state, action) => null,

  [actUpdateUserError]: (state, action) => action.payload.response.data,
  [actUpdateUser]: (state, action) => null,
  [actUpdateUserRequest]: (state, action) => null,

  [actUpdateUserEmailError]: (state, action) => action.payload.response.data,
  [actUpdateUserEmail]: (state, action) => null,
  [actUpdateUserEmailRequest]: (state, action) => null,

  [actUpdateUserPassError]: (state, action) => action.payload.response.data,
  [actUpdateUserPass]: (state, action) => null,
  [actUpdateUserPassRequest]: (state, action) => null,

  [actForgotUserPassError]: (state, action) => action.payload.response.data,
  [actForgotUserPass]: (state, action) => null,
  [actForgotUserPassRequest]: (state, action) => null,

  [actUserSendEmailError]: (state, action) => action.payload.response.data,
  [actUserSendEmail]: (state, action) => null,
  [actUserSendEmailRequest]: (state, action) => null,

  [actDeleteUserError]: (state, action) => action.payload.response.data,
  [actDeleteUser]: (state, action) => null,
  [actDeleteUserRequest]: (state, action) => null,


  // ----------------------------------------------------------------- Юзер - КОМПАНИЯ:
  [actCreateCompanyError]: (state, action) => action.payload.response.data,
  [actCreateCompany]: (state, action) => null,
  [actCreateCompanyRequest]: (state, action) => null,

  [actUpdateCompanyContactsError]: (state, action) => action.payload.response.data,
  [actUpdateCompanyContacts]: (state, action) => null,
  [actUpdateCompanyContactsRequest]: (state, action) => null,

  [actGetUserCompanyError]: (state, action) => action.payload.response.data,
  [actGetUserCompany]: (state, action) => null,
  [actGetUserCompanyRequest]: (state, action) => null,

  [actUpdateCompanyStatusError]: (state, action) => action.payload.response.data,
  [actUpdateCompanyStatus]: (state, action) => null,
  [actUpdateCompanyStatusRequest]: (state, action) => null,

  [actUpdateCompanyError]: (state, action) => action.payload.response.data,
  [actUpdateCompany]: (state, action) => null,
  [actUpdateCompanyRequest]: (state, action) => null,

  [actUpdateCompanyPhotoError]: (state, action) => action.payload.response.data,
  [actUpdateCompanyPhoto]: (state, action) => null,
  [actUpdateCompanyPhotoRequest]: (state, action) => null,

  [actUpdateCompanyLogoError]: (state, action) => action.payload.response.data,
  [actUpdateCompanyLogo]: (state, action) => null,
  [actUpdateCompanyLogoRequest]: (state, action) => null,

  // ----------------------------------------------------------------- Юзер - ФОТО:
  [actGetUserCompanyPhotoError]: (state, action) => action.payload.response.data,
  [actGetUserCompanyPhoto]: (state, action) => null,
  [actGetUserCompanyPhotoRequest]: (state, action) => null,

  [actDeleteCompanyPhotoError]: (state, action) => action.payload.response.data,
  [actDeleteCompanyPhoto]: (state, action) => null,
  [actDeleteCompanyPhotoRequest]: (state, action) => null,

  [actDeleteCompanyError]: (state, action) => action.payload.response.data,
  [actDeleteCompany]: (state, action) => null,
  [actDeleteCompanyRequest]: (state, action) => null,

  // ----------------------------------------------------------------- Юзер - НОВОСТИ:
  [actCreateNewsError]: (state, action) => action.payload.response.data,
  [actCreateNews]: (state, action) => null,
  [actCreateNewsRequest]: (state, action) => null,

  [actGetUserNewsError]: (state, action) => action.payload.response.data,
  [actGetUserNews]: (state, action) => null,
  [actGetUserNewsRequest]: (state, action) => null,

  [actUpdateUserNewsError]: (state, action) => action.payload.response.data,
  [actUpdateUserNews]: (state, action) => null,
  // [actUpdateUserNewsRequest]: (state, action) => null,

  [actUpdateUserNewsPhotoError]: (state, action) => action.payload.response.data,
  [actUpdateUserNewsPhoto]: (state, action) => null,
  // [actUpdateUserNewsPhotoRequest]: (state, action) => null,

  [actUpdateUserNewsStatusAllError]: (state, action) => action.payload.response.data,
  [actUpdateUserNewsStatusAll]: (state, action) => null,
  // [actUpdateUserNewsPhotoRequest]: (state, action) => null,

  [actUpdateUserNewsStatusSuperError]: (state, action) => action.payload.response.data,
  [actUpdateUserNewsStatusSuper]: (state, action) => null,
  // [actUpdateUserNewsStatusSuperRequest]: (state, action) => null,


  [actDeleteNewsError]: (state, action) => action.payload.response.data,
  [actDeleteNews]: (state, action) => null,
  [actDeleteNewsRequest]: (state, action) => null,


  // ----------------------------------------------------------------- Приведение в дефолтное состояние Error:
  [actDefaultError]: (state, action) => null,
  },
);



export {errorReducer};

