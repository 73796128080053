import { combineReducers } from 'redux';
// Старая запись прослойки:
// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { configureStore} from '@reduxjs/toolkit';
import { persistStore, persistReducer, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import logger from 'redux-logger';

// import { createStore, combineReducers  } from 'redux';

// ----------------------> Применяем для (redux-persist) что бы не было ошибки в консоле
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import newsReducer from './reducers/news';
import { userReducer} from './reducers/userAuth';
import { authorizationReducer } from './reducers/authorization';
import company from './reducers/company';
import {currentCompany} from './reducers/companyCurrent';
import companyUser from './reducers/user';
import {errorReducer} from './reducers/error'
import {loadingReducer} from './reducers/loading';
import { successReducer } from './reducers/success';

// -----------------------> Настраиваем (redux-persist):
const persistConfig = {
  // key - это то как наша инфо будет называться в LocaleStorage
  key: 'token',
  // Передаем ссылку на хранилище:
  storage,
  // blacklist: ['filterValue', 'contacts', 'loading']   //----> Исключили ненужные ключи, есть еще вайтлист
  whitelist: ['token']
}


const rootReducer = combineReducers({
  // Чтобы передать в Юзер весь редюсер, то нужно его как то обозвать и не делать диструктуризацию при импорте(например еще Авторизацию):
  // token: persistReducer(persistConfig, tokenReducer),
  // Статус авторизации:
  isAuthorized: authorizationReducer,
  // Статус загрузки:
  loading: loadingReducer,
  // Список всех новостей:
  news: newsReducer,
  // Вся информация о авторизации Юзера:
  userAuth: persistReducer(persistConfig, userReducer),
  // Вся информация о Компании Юзера:
  user: companyUser,
  // Фильтр Компаний: Девелопмент, Стротиельство, Проектирование
  companies: company,
  currentCompany: currentCompany,
  // Сообщение об ошибке:
  error: errorReducer,
  success: successReducer,


  // TODO:Пример Локально-Глобально
  // 1) Обернули наш Редюсер в Персист (это если локально):
  // list: persistReducer(persistConfig, listReducer)
});

// TODO:
// 1) Обернули наш Редюсер в Персист (это если глобально)
// const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  // TODO: Тут в зависимости какой у нас Персист (Локальный или Глобальный) мы соответственно и передаем
  // reducer: persistedReducer,
  reducer: rootReducer,
  // Если у нас Локально, то можно и без combineReducers, то:

    // FIXME: Настройки читай ниже:
    // devTools - включает и выключает доступ к стейту через веббраузер. Что бы к нему не было доступа нужно ставить false, по дефолту true
    // process.env.NODE_ENV - определяет в каком режиме мы сейчас в Продакшене или Девелопменте
    // process.env.NODE_ENV ==='development' - включаем доступ когда мы в режиме разработки:
    // FIXME:
    // devTools: process.env.NODE_ENV ==='development',
    devTools: false,
    // .concat(logger) - включить когда ты в режиме разработке
    // Применяем прослойку для (redux-persist) что бы не было ошибки в консоле:
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    // Подключает Логггер
    // FIXME:
    // .concat(logger)
})



const storeWithPersister = persistStore(store);

// Обязательно экспортируем оба:
export { store, storeWithPersister };
