import { createAction } from '@reduxjs/toolkit';

// ------------------------------------------------------> Создание Новостей:
const actCreateNews = createAction('@CreateNews/SUCCSESS');
const actCreateNewsError = createAction('@CreateNews/ERROR');
const actCreateNewsRequest = createAction('@CreateNews/REQUEST');

// ------------------------------------------------------> Получаем Новости Юзера:
const actGetUserNews = createAction('@GetUserNews/SUCCSESS');
const actGetUserNewsError = createAction('@GetUserNews/ERROR');
const actGetUserNewsRequest = createAction('@GetUserNews/REQUEST');

// ------------------------------------------------------> Обновление Последней Новости (текст):
const actUpdateUserNews = createAction('@UpdateUserNews/SUCCSESS');
const actUpdateUserNewsError = createAction('@UpdateUserNews/ERROR');
const actUpdateUserNewsRequest = createAction('@UpdateUserNews/REQUEST');

// ------------------------------------------------------> Обновление Последней Новости (фото):
const actUpdateUserNewsPhoto = createAction('@UpdateUserNewsPhoto/SUCCSESS');
const actUpdateUserNewsPhotoError = createAction('@UpdateUserNewsPhoto/ERROR');
const actUpdateUserNewsPhotoRequest = createAction('@UpdateUserNewsPhoto/REQUEST');

// ------------------------------------------------------> Обновление Статуса ВСЕХ Новостей:
const actUpdateUserNewsStatusAll = createAction('@UpdateUserNewsStatusAll/SUCCSESS');
const actUpdateUserNewsStatusAllError = createAction('@UpdateUserNewsStatusAll/ERROR');
const actUpdateUserNewsStatusAllRequest = createAction('@UpdateUserNewsStatusAll/REQUEST');

// ------------------------------------------------------> Обновление Статуса Новостей (SUPER-USER):
const actUpdateUserNewsStatusSuper = createAction('@UpdateUserNewsStatusSuper/SUCCSESS');
const actUpdateUserNewsStatusSuperError = createAction('@UpdateUserNewsStatusSuper/ERROR');
const actUpdateUserNewsStatusSuperRequest = createAction('@UpdateUserNewsStatusSuper/REQUEST');

// ------------------------------------------------------> DELETE News:
const actDeleteNews = createAction('@DeleteNews/SUCCSESS');
const actDeleteNewsError = createAction('@DeleteNewsy/ERROR');
const actDeleteNewsRequest = createAction('@DeleteNews/REQUEST');

export {
  actCreateNews,
  actCreateNewsError,
  actCreateNewsRequest,

  actGetUserNews,
  actGetUserNewsError,
  actGetUserNewsRequest,

  actUpdateUserNews,
  actUpdateUserNewsError,
  actUpdateUserNewsRequest,
  
  actUpdateUserNewsPhoto,
  actUpdateUserNewsPhotoError,
  actUpdateUserNewsPhotoRequest,

  actUpdateUserNewsStatusAll,
  actUpdateUserNewsStatusAllError,
  actUpdateUserNewsStatusAllRequest,

  actUpdateUserNewsStatusSuper,
  actUpdateUserNewsStatusSuperError,
  actUpdateUserNewsStatusSuperRequest,

  actDeleteNews,
  actDeleteNewsError,
  actDeleteNewsRequest,
};