import { createAction } from '@reduxjs/toolkit';


// ------------------------------------------------------> Обнуление в State раздела Error:
const actDefaultError = createAction('@DefaultError');

// ------------------------------------------------------> Обнуление в State раздела CurrentCompany:
const actDefaultCompanyCurrent = createAction('@DefaultCurrentCompany');

// ------------------------------------------------------> Обнуление в State раздела Success:
const actDefaultSuccess = createAction('@DefaultSuccess');

export {
  actDefaultError,
  actDefaultCompanyCurrent,
  actDefaultSuccess,
  };