// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n/* ---------------------Старое---------------- */\n.Header_flexbox__8faf5 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n\n.Header_link__PKMfc {\n  /* color: white;\n  width: 100px; */\n  text-decoration: none;\n  margin: 0px;\n}\n\n/* .link:hover,\n.link:focus,\n.link__mobile:hover,\n.link__mobile:focus {\n  color: #2196f3;\n} */\n", "",{"version":3,"sources":["webpack://./src/cections/section-Header/Header.module.css"],"names":[],"mappings":";AACA,gDAAgD;AAChD;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;;AAGA;EACE;iBACe;EACf,qBAAqB;EACrB,WAAW;AACb;;AAEA;;;;;GAKG","sourcesContent":["\n/* ---------------------Старое---------------- */\n.flexbox {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n\n.link {\n  /* color: white;\n  width: 100px; */\n  text-decoration: none;\n  margin: 0px;\n}\n\n/* .link:hover,\n.link:focus,\n.link__mobile:hover,\n.link__mobile:focus {\n  color: #2196f3;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexbox": "Header_flexbox__8faf5",
	"link": "Header_link__PKMfc"
};
export default ___CSS_LOADER_EXPORT___;
