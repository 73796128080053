import { createReducer } from '@reduxjs/toolkit';

import { ERROR_ACCESS_WRONG } from '../../helpers/constants';

import {
  actRegister,
  actRegisterError,

  actLogin,
  actLoginError,

  actLogout,
  actLogoutError,

  actCurrentUser,
  actCurrentUserError,

  // actUpdateUser,
  actUpdateUserError,

  actUpdateUserEmail,
  actUpdateUserEmailError,

  // actUpdateUserPass,
  actUpdateUserPassError,

  actDeleteUser,
  // actDeleteUserError,
} from '../actions/userAuth';

import {
  actCreateCompanyError,
  actGetUserCompanyError,
  actUpdateCompanyContactsError,
  actDeleteCompanyPhotoError,
  actDeleteCompanyError,
  actUpdateCompanyStatusError,
  actUpdateCompanyError,
  actUpdateCompanyPhotoError,
  actUpdateCompanyLogoError,
  actGetUserCompanyPhotoError,
} from '../actions/userCompany';

import {
  // actCreateNews,
  actCreateNewsError,

  // actGetUserNews,
  actGetUserNewsError,

  // actUpdateUserNews,
  actUpdateUserNewsError,

  // actUpdateUserNewsPhoto,
  actUpdateUserNewsPhotoError,

  // actUpdateUserNewsStatusAll,
  actUpdateUserNewsStatusAllError,

  // actUpdateUserNewsStatusSuper,
  actUpdateUserNewsStatusSuperError,

  // actDeleteNews,
  actDeleteNewsError,
} from '../actions/userNews';

import {
  // actChangeFavorite,
  actChangeFavoriteError,

  // actGetCompanyFavorite,
  actGetCompanyFavoriteError,

  // actChangeLike,
  actChangeLikeError,
} from '../actions/company';

import { 
  // actNewsPublic, 
  actNewsPublicError,
} from '../actions/news';

// --------------------------------------------------> Authorization status
const authorizationReducer = createReducer(false, {

  // ------------------------------------------------------------ Юзер - Авторизация:
  [actRegister]: () => false,
  [actRegisterError]: () => false,

  [actLogin]: () => true,
  [actLoginError]: () => false,

  [actLogout]: () => false,
  [actLogoutError]: () => false,

  [actCurrentUser]: () => true,
  [actCurrentUserError]: () => false,

  // При смене почты сбрасываем Авторизацию, для перезахода Юзера
  [actUpdateUserEmail]: () => false,

  [actDeleteUser]: () => false,
  // При ошибки ничего не делаем:
  // [actDeleteUserError]: () => true,

  // ------------------------------------------------------------ КОМПАНИЯ:

  [actChangeFavoriteError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actGetCompanyFavoriteError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actChangeLikeError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  // ------------------------------------------------------------ Юзер - КОМПАНИЯ:
  // Если срок действия токена истек, и при каком то запросе будет сообщеине что "Доступ запрещен(Пройдіть авторизацію)"
  // то мы сразу меняем авторизацию Юзера на false или возвращаем наш State
  [actCreateCompanyError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    // TODO: если не вернуть State а что то другое то не будет корректо работать, нужно обязательно что то вернуть!
    return state
  },

  [actUpdateCompanyContactsError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actGetUserCompanyError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateCompanyStatusError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateCompanyError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateCompanyLogoError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actDeleteCompanyError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  // ------------------------------------------------------------ Юзер - ФОТО:
  [actUpdateCompanyPhotoError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actGetUserCompanyPhotoError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actDeleteCompanyPhotoError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  // ------------------------------------------------------------ Юзер - НОВОСТИ:
  [actCreateNewsError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actGetUserNewsError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserNewsError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserNewsPhotoError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserNewsStatusAllError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserNewsStatusSuperError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actNewsPublicError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actDeleteNewsError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  // ------------------------------------------------------------ Юзер:

  [actUpdateUserError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserEmailError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

  [actUpdateUserPassError]: (state, action) => {
    const errorMessage = action.payload.response.data.message;
    if (errorMessage === ERROR_ACCESS_WRONG) {return false}
    return state
  },

});



export { authorizationReducer};
// export default combineReducers({ message, token, isAuthorized, user});
