import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { selAuthorization } from '../redux/selectors/selectorMain';

// Если Юзер зашел то рендерит все маршруты кроме тех маршрутов что ему уже не нужны (по типу страницы Регистриции или Логинизации)

export default function PublickRoute({
  redirectTo,
  children,
  ...routeProps
}) {
  const isAuthorized = useSelector(selAuthorization);
  // const isAuthorized = false;


  return (
    <Route {...routeProps}>
      {isAuthorized && routeProps.restricted ? (
        <Redirect to={redirectTo} />
      ) : (
        children
      )}
    </Route>
  );
}
