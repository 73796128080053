import axios from 'axios';

import { API_URL } from '../helpers/constants';

import {
  actRegister,
  actRegisterError,
  actRegisterRequest,
  actLogin,
  actLoginError,
  actLoginRequest,
  actLogout,
  actLogoutError,
  actLogoutRequest,
  actCurrentUser,
  actCurrentUserError,
  actCurrentUserRequest,

  actUpdateUser,
  actUpdateUserError,
  actUpdateUserRequest,

  actUpdateUserEmail,
  actUpdateUserEmailError,
  actUpdateUserEmailRequest,

  actUpdateUserPass,
  actUpdateUserPassError,
  actUpdateUserPassRequest,

  actForgotUserPass,
  actForgotUserPassError,
  actForgotUserPassRequest,

  actUserSendEmail,
  actUserSendEmailError,
  actUserSendEmailRequest,

  actDeleteUser,
  actDeleteUserError,
  actDeleteUserRequest,
} from '../redux/actions/userAuth';

// --------------------------------------------------> Настройки axios:
axios.defaults.baseURL = API_URL;

const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = '';
  },
};

// --------------------------------------------------> REGISTER
const apiRegisterUser = newUser => dispatch => {
  dispatch(actRegisterRequest());
  axios
    .post('/api/users/registration', newUser)
    .then(responce => dispatch(actRegister(responce.data)))
    .catch(error => {
      // console.log(error)
      return dispatch(actRegisterError(error));
    }
    )
};

// --------------------------------------------------> LOGIN
const apiLoginUser = user => dispatch => {
  dispatch(actLoginRequest());
  axios
    .post('/api/users/login', user)
    .then(responce => {
      // Записали наш токен в настройки Аксиоса:
      token.set(responce.data.data.user.token)
      return dispatch(actLogin(responce.data))})
    .catch(error => {
      // alert("You entered your email or password incorrectly, please check")
      return dispatch(actLoginError(error));
    }
    )
};

// -------------------------------------------------> LOGOUT

const apiLogoutUser = () => dispatch => {
  dispatch(actLogoutRequest());
  axios
    .post('api/users/logout')
    .then(responce => {
      // Удалили наш токен с настроек Аксиоса:
      token.unset();
      return dispatch(actLogout());
    })
    .catch(error => dispatch(actLogoutError(error)));
};

// -----------------------------------------------> Current User (Обновление страницы)

const apiGetCurrentUser = () => (dispatch, getState) => {
  // Нахордим наш токен (в моем случае он лежит в Юзере):
  // 1-й вариант записи:
  // const { token: persistedReducer } = getState();
  // 2-й вариант записи:
  const savedToken = getState().userAuth.token;

  // ---> Проверили есть ли токен в localstorage:
  if (!savedToken) {
    return;
  }


  // Если он есть то сохраняем его в аксиос для запроса:
  token.set(savedToken);

  dispatch(actCurrentUserRequest());
  axios
    .get('/api/users/current')
    .then(responce => dispatch(actCurrentUser(responce.data)))
    .catch(error => dispatch(actCurrentUserError(error)));
};

// -----------------------------------------------> Update User (имя и контакты)

const apiUpdateUser = (body) => (dispatch, getState) => {
  // body - приходит объект с ключами (name или email, или др.)
  // Нахордим наш токен (в моем случае он лежит в Юзере):
  const savedToken = getState().userAuth.token;

  // ---> Проверили есть ли токен в localstorage:
  if (!savedToken) {
    return;
  }

  // Если он есть то сохраняем его в аксиос для запроса:
  token.set(savedToken);

  dispatch(actUpdateUserRequest());
  axios
    .put('/api/users/update', body)
    .then(responce => dispatch(actUpdateUser(responce.data)))
    .catch(error => dispatch(actUpdateUserError(error)));
};

// -----------------------------------------------> Изменение ПОЧТЫ Юзера

const apiUpdateUserEmail = (body) => (dispatch, getState) => {
  // body - приходит объект с ключами (oldEmail, newEmail - только эти ключи воспринимает бэк)
  // Нахордим наш токен (в моем случае он лежит в Юзере):
  const savedToken = getState().userAuth.token;

  // ---> Проверили есть ли токен в localstorage:
  if (!savedToken) {
    return;
  }

  // Если он есть то сохраняем его в аксиос для запроса:
  token.set(savedToken);

  dispatch(actUpdateUserEmailRequest());
  axios
    .put('/api/users/email', body)
    .then(responce => dispatch(actUpdateUserEmail(responce.data)))
    .catch(error => dispatch(actUpdateUserEmailError(error)));
};

// -----------------------------------------------> Изменение ПАРОЛЯ Юзера

const apiUpdateUserPass = (body) => (dispatch, getState) => {
  // body - приходит объект с ключами (oldPass, newPass, confirmPass - только эти ключи воспринимает бэк)
  // Нахордим наш токен (в моем случае он лежит в Юзере):
  const savedToken = getState().userAuth.token;

  // ---> Проверили есть ли токен в localstorage:
  if (!savedToken) {
    return;
  }

  // Если он есть то сохраняем его в аксиос для запроса:
  token.set(savedToken);

  dispatch(actUpdateUserPassRequest());
  axios
    .put('/api/users/password', body)
    .then(responce => dispatch(actUpdateUserPass(responce.data)))
    .catch(error => dispatch(actUpdateUserPassError(error)));
};

// -----------------------------------------------> Восстановление ПАРОЛЯ Юзера

const apiForgotUserPass = (body) => (dispatch, getState) => {

  dispatch(actForgotUserPassRequest());
  axios
    .put('/api/users/passwordForgot', body)
    .then(responce => dispatch(actForgotUserPass(responce.data)))
    .catch(error => dispatch(actForgotUserPassError(error)));
};

// -----------------------------------------------> Обратная связь от Юзера
const apiUserSendEmail = (letter) => (dispatch, getState) => {

  dispatch(actUserSendEmailRequest());
  axios
    .put('/api/users/sendEmail', letter)
    .then(responce => dispatch(actUserSendEmail(responce.data)))
    .catch(error => dispatch(actUserSendEmailError(error)));
};

// -----------------------------------------------> Удаление Юзера

const apiDeleteUser = () => (dispatch, getState) => {
    // Находим наш токен (в моем случае он лежит в Юзере):
    const savedToken = getState().userAuth.token;
  
    // ---> Проверили есть ли токен в localstorage:
    if (!savedToken) {
      return;
    }
  
    // Если он есть то сохраняем его в аксиос для запроса:
    token.set(savedToken);
  
    dispatch(actDeleteUserRequest());
    axios
      .delete('/api/users')
      .then(responce => dispatch(actDeleteUser(responce.data)))
      .catch(error => dispatch(actDeleteUserError(error)));
  };
  


export { 
  apiRegisterUser, 
  apiLoginUser, 
  apiLogoutUser, 
  apiGetCurrentUser,
  apiUpdateUser,
  apiUpdateUserEmail,
  apiUpdateUserPass,
  apiForgotUserPass,
  apiUserSendEmail,
  apiDeleteUser,
 };
