import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Импорт из библиотеки:
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Modal from '@mui/material/Modal';
// Импорт иконок из библиотеки:
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

// Импорт медиазапросов из библиотеки:
// import useMediaQuery from '@mui/material/useMediaQuery';

// Импорт моих Компоненнтов:
import MyLoading from '../../components/Elements/Loading/Loading'
import { ButtonStyleFoot } from '../../components/Elements/Button/ButtonStyle';
import Navigation from '../section-Navigation/Navigation';

// Импорт путей:
import route from '../../routes/routes';
// Импорт глобального State:
import { selError, selLoadingStatus, selSuccess, selUserId } from '../../redux/selectors/selectorMain';
// Импорт действий связаных со State:
import {actDefaultError, actDefaultSuccess} from '../../redux/actions/helpers'
// Импорт статических переменных:
import {BUILDHUB_EMAIL_INFO, COLOR_TEXT_FORM, COLOR_TEXT_ACCENT, BUILD_HUB, FONT_FAMILY, COLOR_TEXT_MAIN, } from '../../helpers/constants';
// Импорт запросов:
import { apiUserSendEmail } from '../../api/api-userAuth';

// Импорт Стилей:
import '../../App.css';
import styles from './Footer.module.css';
import { LogoStyle } from '../../components/Elements/Style/TextStyle';


// Настройка модального окна (взято из библиотеки полностью):
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  width: {xs: '100%', sm: 400},
  height: {xs: '100%', sm: '720px'},
  // maxWidth: 400,
  overflowY: 'scroll', // вертикальный скрол
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // position: 'relative' // для кнопки закрытия Х
};


export default function Footer() {
  // console.log('Обновли страницу Footer')

  // const mobile = useMediaQuery('(max-width:767px)');
  // const tablet = useMediaQuery('(max-width:1199px)');
  // const desktop = useMediaQuery('(min-width:1200px)');

  const dispatch = useDispatch();

  // Открывание модального окна:
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    // При открытии модалки сбрасываем Error к дефолтному состоянию: 
    dispatch(actDefaultError());
  };
  const handleClose = () => {
    setOpen(false);
    // При закрытии модалки сбрасываем Success к дефолтному состоянию: 
    dispatch(actDefaultSuccess());
  };

  const userId = useSelector(selUserId)
  const Success = useSelector(selSuccess)
  const Error = useSelector(selError)
  const Loading = useSelector(selLoadingStatus)



  // -----------------------------------------> Ф-я отправки формы:
  const fnSubmit = event => {
    // TODO: Внимание! идет привязка к индексам, если добавятся Компоненты то логика полетит
    // console.log("🚀 ~ file: 0-CreateCompany.js ~ line 197 ~ CreateCompany ~  event",  event)

    // Сбросили перезагрузку страницы:
    event.preventDefault();
    // Создали запрос:
    const letter = {
      "userId": userId ? userId : ' ', // Если Юзер не ввошел (айди нету), то ставим пустую строку
      "emailContact": event.target[1].value,
      // Если в инпуте что то есть, то мы его и записываем, если нету то пустую строку (с пробелом, что бы бэк прочитал) - телефон и ссылка просто не обязательный параметр!
      "phoneContact": event.target[2].value ? event.target[2].value : ' ',
      "link": event.target[3].value ? event.target[3].value : ' ',
      "text": event.target[4].value,
    }

    // console.log("🚀 ~ file: Footer.js:99 ~ fnSubmit ~ letter:", letter)
    // Отправили на бэк:
    
    dispatch(apiUserSendEmail(letter))
    return
  }

  return (
    <section className="App-Footer">
      {/* =================================================== РЕНДЕР-1 - Секция Футер ================================================== */}
      <Box sx={{ maxWidth: { xs: '320px', sm: '768px', lg: '1200px' }, marginX: 'auto', paddingY: {xs: '15px', lg: '25px'}, paddingX: {xs: '15px', lg: '25px'},  
      fontSize: { xs: '12px', sm: '14px' } }}>
        <Box sx={{ display: {sm: 'flex'}, 
        justifyContent: {sm: 'space-around', lg: 'space-between'}, 
        alignItems: 'center',}} >
          {/* FIXME: style.title_active - не дйствует */}
          <NavLink to={route.home} className="link" activeClassName={styles.title_active}>
            <LogoStyle sx={{fontSize: {sm: '26px'}, }}>
              {BUILD_HUB}
            </LogoStyle>
          </NavLink>
          <div>
            <Navigation/>
          </div>
        </Box>
        <Box className={style.box}
        sx={{color: COLOR_TEXT_MAIN, justifyContent: {sm: 'space-between', lg: 'space-between'}, flexWrap: 'wrap', alignItems: 'center', 
        fontSize: { xs: '10px', sm:'12px', lg:'14px'}, display: { lg: 'flex' },  marginTop: {xs: '10px', sm: '20px'}, marginX: {sm: '15px', lg: '0px'}}}>
          <ul className={styles.list}>
            <li>Є пропозиції щодо роботи ресурсу?</li>
            <li>
              Бажаєш поділитись своїми досягненнями в будівництві на нашому
              порталі?
            </li>
            <li>
              Хочеш презентувати новий матеріал чи обладнання щоб всі читачі з
              ним ознайомились?
            </li>
          </ul>
            <ButtonStyleFoot sx={{height: {sm: 'auto', lg: '35px'}, padding: {lg: '10px'}, marginTop: {xs: '10px', sm: '15px', lg: '0px'}, fontSize: { xs: '10px', sm:'12px', lg:'14px'}}} 
            onClick={handleOpen}>Зв'яжись з нами і ми все зробимо!</ButtonStyleFoot>
        </Box>
        <Typography sx={{color: COLOR_TEXT_MAIN, fontFamily: FONT_FAMILY, fontSize: { xs: '8px', sm:'10px', lg:'12px'}, marginTop: {xs: '15px', sm: '20px', lg: '30px'}}} className={styles.textRights}>
          U k r a i n e - 2 0 2 2 :    A l l    r i g h t s    r e s e r v e d
        </Typography>
      </Box>
      {/* ===================================================== РЕНДЕР-2 - Модальное окно ================================================ */}
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} component="form" autoComplete="off" onSubmit={fnSubmit}>
        <Typography id="feedback-title" variant="h6" component="h2" sx={{marginBottom: '15px', textAlign: 'center'}}>
          Відправити запит
        </Typography>
        <IconButton aria-label="close" sx={{position: 'absolute', top: '0px', right: '0px'}} onClick={handleClose}>
          <HighlightOffOutlinedIcon sx={{ fontSize: 27, color: COLOR_TEXT_FORM}}/>
        </IconButton>
        {Success !== 'SendEmail' && (
        <div>
          <TextField id="feedback-email" label="Ваш контактний email:" variant="standard" name="email" fullWidth
          required 
          type='email'
          inputProps={{ maxLength: '30', pattern: '^[a-z0-9._-]+@[a-z0-9-]+.[a-z]{2,5}$'}}/>
          <TextField id="feedback-phone" label="Ваш контактний номер телефону:" variant="standard" placeholder="380" name="phone" fullWidth
          type='tel' inputProps={{ inputMode: 'numeric', pattern: '[0-9]{12,14}' }}/>
          <TextField id="feedback-link" label="Посилання за необхідності:" variant="standard" helperText="Посилання на ваші Новини, Компанію, проблему тощо" name="link" fullWidth
          type='text'inputProps={{maxLength: '200'}}/>
          <TextField id="feedback-text"label="Повідомлення:" variant="standard" placeholder="Введіть текст повідомлення" multiline rows={9} name="description" fullWidth 
          required 
          type='text' inputProps={{minLength: '50', maxLength: '3000'}} // минимальная длина текста 50
          sx={{marginY: '15px'}}/> 
          <Button variant="outlined" sx={{display: 'block', marginX: 'auto'}} type='submit'>Відправити</Button>
          <FormHelperText sx={{ fontSize: '12px', textAlign: 'left', marginTop: '15px' }}>
            * Поля обов'язкові для заповнення
          </FormHelperText>
          <FormHelperText sx={{ fontSize: '12px', textAlign: 'left' }}>
            ** У випадку оформлення VIP статусу - спочатку увійдіть на сайт, після чого надішліть повідомлення
          </FormHelperText>
          <FormHelperText sx={{ fontSize: '12px', textAlign: 'left' }}>
            *** Якщо виникли проблеми з відправкою листа, то ви можете написати нам на цю пошту: 
            <span style={{color: COLOR_TEXT_FORM}}>{BUILDHUB_EMAIL_INFO}</span>
          </FormHelperText>
        </div>
        )}
        {/* ====================================================== РЕНДЕР-3 - Сообщение об успехе =========================================== */}
        {Success === 'SendEmail' && (
          <Box sx={{textAlign: 'center'}}>
            <TaskAltIcon fontSize="large" color='success'/>
            <Typography sx={{fontSize: {xs: '12px', sm: '14px', lg: '16px'}, marginTop: '15px'}}>Ваш запит успішно направлено. При необхідності ми з вами скоро зв'яжемось</Typography>
          </Box>
          )}
        {/* ====================================================== РЕНДЕР-4 - Сообщение о ошибке ============================================ */}
        {Error && (
          <div>
            <p style={{color: 'red'}}>*** Нажаль, виникла помилка. Будь-ласка спробуйте пізніше.</p>
          </div>
          )}
        {/* ====================================================== РЕНДЕР-5 - Loading ======================================================= */}
        {Loading && <MyLoading color={COLOR_TEXT_ACCENT}/>}
        </Box>
      </Modal>
    </section>
  );
}
