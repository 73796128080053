import React from 'react';
import PropTypes from 'prop-types';
import {ThreeDots} from 'react-loader-spinner';


export default function Loading({ color, size }) {
  return (
      <div style={{ display: 'flex', justifyContent: 'center', 
      paddingTop: '20px', 
      // FIXME: size - передається тільки з Аpp, тому в інших випадка може бити помилку (монітор помилки!)
      marginTop: size,
      }}>
        <ThreeDots color={color} 
        height={12} width={50} 
        />
      </div>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};
