import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { selAuthorization } from '../redux/selectors/selectorMain';


// Если Юзер зашел то рендерит приватный маршрут:

export default function PrivateRoute({
  redirectTo,
  children,
  ...routeProps
}) {
  const isAuthorized = useSelector(selAuthorization);
  // const isAuthorized = true;
  return (
    <Route {...routeProps}>
      {isAuthorized ? (children) : (<Redirect to={redirectTo} />)
  }
  </Route>
  )
}
