import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { fnListSort } from '../../function/fn';

import { 
  actNewsGlobal, 
  // actNewsGlobalError, 
  // actNewsGlobalRequest, 

  actNewsLocal, 
  // actNewsLocalError, 
  // actNewsLocalRequest,

  actNewsCurrent,

  actNewsLocalCurrent, 
  // actNewsLocalCurrentError, 
  actNewsLocalCurrentRequest,

  actNewsPublic, 
  // actNewsPublicError,
  // actNewsPublicRequest,
} from '../actions/news';

import { 
  actUpdateUserNewsStatusSuper,
  // actUpdateUserNewsStatusSuperError,
  // actUpdateUserNewsStatusSuperRequest,
} from '../actions/userNews';


// ------------------------------------------------------> Внешние Новости - GLOBAL NEWS

const newsGlobal = createReducer([], {
  [actNewsGlobal]: (state, action) => action.payload,
}
)

// ------------------------------------------------------> Внутринние Новости - LOCAL NEWS
const newsLocal = createReducer([], {
  [actNewsLocal]: (state, action) => action.payload.data.news,
}
)

// ------------------------------------------------------> Внутринние Новости - LOCAL NEWS (Public/Active/Check)
const newsCurrent = createReducer(null, {
  // actNewsCurrent - это внутренняя запись найденой новости в State, без запроса на бэк
  [actNewsCurrent]: (state, action) => action.payload,
  // actNewsLocalCurrent - это при перезагрузке страницы мы делаем запрос на бэк
  [actNewsLocalCurrent]: (state, action) => action.payload.data.news,
  // При ззапросе на бэк, на всякий случай скидываем в ноль:
  [actNewsLocalCurrentRequest]: (state, action) => null
}
)

// ------------------------------------------------------> Внутринние Новости - Public NEWS All (SUPER-USER)
const newsPublic = createReducer([], {
  [actNewsPublic]: (state, action) => action.payload.data.news,

  [actUpdateUserNewsStatusSuper]: (state, action) => {
    // 1) Нашли айди Новости в которй сделали изменение:
    let idNews = action.payload.data.news._id
    // 2) Исключили Новость по методу фильтрации:
    let newState = state.filter(item => item._id !== idNews)
    // 3) Добавили обновленную Новость в общий массив:
    newState.push(action.payload.data.news)
    // 4) Заново отсортировали весь массив по дате создания:
    let sortState = fnListSort(newState, 'dateDefault')
    return sortState
  }
}
)

export default combineReducers({newsGlobal, newsLocal, newsCurrent, newsPublic});