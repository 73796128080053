import React from 'react';
import PropTypes from 'prop-types';

// Импорт из библиотеки:
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// Импорт моих переменных:
import { COLOR_TEXT_ERROR } from '../../../helpers/constants';


export default function WrongRoute({text}) {
  // console.log('Обновли страницу WrongRoute')

  return (
    <Box sx={{width: '100%', marginX: 'auto', marginBottom: '15px'}}>
      <Typography color={COLOR_TEXT_ERROR} sx={{ fontWeight: '700', fontSize: { xs: '14px', sm: '16px', lg: '18px' }}}>
        {text}
      </Typography>
      <Skeleton variant="rectangular" width={"100%"} height={500} />
    </Box>
  );
}

WrongRoute.propTypes = {
  text: PropTypes.string,
};


