import { createReducer } from '@reduxjs/toolkit';

import {
  actGetCompanyCurrent,
  // actGetCompanyCurrentError,
  // actGetCompanyCurrentRequest,
} from '../actions/company';

import { actDefaultCompanyCurrent } from '../actions/helpers';

// --------------------------------------------------> Companies State

const defaultCompany = { }

const currentCompany = createReducer(defaultCompany,{
  [actGetCompanyCurrent]: (state, action) => action.payload.data,
  // Сбрасывает заначение к дефолту:
  [actDefaultCompanyCurrent]: (state, action) => defaultCompany
  },
);


export {currentCompany};
