import { createAction } from '@reduxjs/toolkit';

// ------------------------------------------------------> REGISTER User (Пример)

// const actRegister = createAction('@RegisterUser/SUCCSESS', test => {
//   // Вторым аргументом можно добавитьть до нашего payload доп. инфо
//   return {
//     payload: {
//       id,
//       test,
//       status: false
//     }
//   }
// });

const actRegister = createAction('@RegisterUser/SUCCSESS');

const actRegisterError = createAction('@RegisterUser/ERROR');

const actRegisterRequest = createAction('@RegisterUser/REQUEST');

// ------------------------------------------------------> LOGIN User

const actLogin = createAction('@LoginUser/SUCCSESS');
const actLoginError = createAction('@LoginUser/ERROR');
const actLoginRequest = createAction('@LoginUser/REQUEST');

// ------------------------------------------------------> LOGOUT User

const actLogout = createAction('@LogoutUser/SUCCSESS');
const actLogoutError = createAction('@LogoutUser/ERROR');
const actLogoutRequest = createAction('@LogoutUser/REQUEST');

// ------------------------------------------------------> Get Current User

const actCurrentUser = createAction('@GetCurrentUser/SUCCSESS');
const actCurrentUserError = createAction('@GetCurrentUser/ERROR');
const actCurrentUserRequest = createAction('@GetCurrentUser/REQUEST');

// ------------------------------------------------------> Update User

const actUpdateUser = createAction('@UpdateUser/SUCCSESS');
const actUpdateUserError = createAction('@UpdateUser/ERROR');
const actUpdateUserRequest = createAction('@UpdateUser/REQUEST');

// ------------------------------------------------------> Update User Email

const actUpdateUserEmail = createAction('@UpdateUserEmail/SUCCSESS');
const actUpdateUserEmailError = createAction('@UpdateUserEmail/ERROR');
const actUpdateUserEmailRequest = createAction('@UpdateUserEmail/REQUEST');

// ------------------------------------------------------> Update User Password

const actUpdateUserPass = createAction('@UpdateUserPass/SUCCSESS');
const actUpdateUserPassError = createAction('@UpdateUserPass/ERROR');
const actUpdateUserPassRequest = createAction('@UpdateUserPass/REQUEST');


// ------------------------------------------------------> Forgot User Password

const actForgotUserPass = createAction('@ForgotUserPass/SUCCSESS');
const actForgotUserPassError = createAction('@ForgotUserPass/ERROR');
const actForgotUserPassRequest = createAction('@ForgotUserPass/REQUEST');

// ------------------------------------------------------> Send Email

const actUserSendEmail = createAction('@UserSendEmail/SUCCSESS');
const actUserSendEmailError = createAction('@UserSendEmail/ERROR');
const actUserSendEmailRequest = createAction('@UserSendEmail/REQUEST');

// ------------------------------------------------------> Delete User

const actDeleteUser = createAction('@DeleteUser/SUCCSESS');
const actDeleteUserError = createAction('@DeleteUser/ERROR');
const actDeleteUserRequest = createAction('@DeleteUser/REQUEST');


export {
  actRegister,
  actRegisterError,
  actRegisterRequest,
  actLogin,
  actLoginError,
  actLoginRequest,
  actLogout,
  actLogoutError,
  actLogoutRequest,
  actCurrentUser,
  actCurrentUserError,
  actCurrentUserRequest,

  actUpdateUser,
  actUpdateUserError,
  actUpdateUserRequest,

  actUpdateUserEmail,
  actUpdateUserEmailError,
  actUpdateUserEmailRequest,

  actUpdateUserPass,
  actUpdateUserPassError,
  actUpdateUserPassRequest,

  actForgotUserPass,
  actForgotUserPassError,
  actForgotUserPassRequest,

  actUserSendEmail,
  actUserSendEmailError,
  actUserSendEmailRequest,
  
  actDeleteUser,
  actDeleteUserError,
  actDeleteUserRequest,
};
