// !!! Ниже стилизация кнопок:

// Импорт из библиотеки:
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// Импорт моих переменных:
import { FONT_FAMILY, COLOR_TEXT_MAIN, COLOR_TEXT_SUBMAIN, COLOR_TEXT_ACCENT, COLOR_TEXT_FORM,} from '../../../helpers/constants';

// 1) Стилизация Кнопки для навигации:
const ButtonStyleNav = styled(Button)({
  // marginX: 10,
  marginLeft: 10,
  marginRight: 10,
  // marginTop: 30,
  // marginBottom: 30,
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  // fontSize: 16,
  color: COLOR_TEXT_MAIN,
  // boxShadow: 'none',
  textTransform: 'none',
  // fontSize: 12,
  // cursor: 'default',
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: '#0063cc',
  // borderColor: '#0063cc',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: 'white',
  //   // borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});

// 2) Стилизация Кнопки для героя:
const ButtonStyleHero = styled(Button)({
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  color: COLOR_TEXT_MAIN,
  // boxShadow: 'none',
  textTransform: 'none',
  // cursor: 'default',
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  backgroundColor: COLOR_TEXT_ACCENT,
  // borderColor: '#0063cc',
  // borderRadius: 10,
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: 'white',
  //   // borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});

// 3) Аналогічний ButtonStyleHero, просто вимкнули ховвер (це як тимчасове рішення)
const ButtonStyleHeroDisabled = styled(Button)({
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  color: COLOR_TEXT_MAIN,
  boxShadow: 'none',
  textTransform: 'none',
  cursor: 'default',
  backgroundColor: COLOR_TEXT_ACCENT,
  '&:hover': {
    backgroundColor: COLOR_TEXT_ACCENT,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

// 4) Стилизация Кнопки для Регистрации:
const ButtonStyleSec = styled(Button)({
  marginTop: 10,
  fontFamily: FONT_FAMILY,
  // fontStyle: normal,
  fontWeight: 400,
  // color: COLOR_TEXT_SUBMAIN,
  color: COLOR_TEXT_FORM,
  // boxShadow: 'none',
  textTransform: 'none',
  // fontSize: 12,
  // cursor: 'default',
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: '#2656FF',
  // borderColor: '#0063cc',
  borderRadius: 10,
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: 'white',
  //   // borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  '&:disabled': {
    // boxShadow: 'none',
    borderColor: '#005cbf',
    // color: COLOR_TEXT_SUBMAIN,
    color: COLOR_TEXT_FORM,
    opacity: 0.6,
  },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});

// 5) Стилизация Кнопки для Футтера:
const ButtonStyleFoot = styled(Button)({
  fontFamily: FONT_FAMILY,
  // fontStyle: normal,
  // padding: '10px',
  fontWeight: 400,
  color: COLOR_TEXT_MAIN,
  // color: '#FFFFFF',
  // boxShadow: 'none',
  textTransform: 'none',
  // fontSize: 12,
  // cursor: 'default',
  // padding: '6px 12px',
  border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: '#2656FF',
  borderColor: COLOR_TEXT_MAIN,
  borderRadius: 10,
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: 'white',
  //   // borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});

// 6) Стилизация Кнопки для редактирования инфо Юзера:
const ButtonStyleUser = styled(Button)({
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  fontSize: '12px',
  color: COLOR_TEXT_MAIN,
  // boxShadow: 'none',
  textTransform: 'none',
  // cursor: 'default',
  // padding: '6px 12px',
  border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: COLOR_TEXT_ACCENT,
  borderColor: COLOR_TEXT_ACCENT,
  // zIndex: 1,
  // borderRadius: 10,
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: 'inherit',
  //   backgroundColor: COLOR_TEXT_BACK,
  //   // borderColor: '#0062cc',
  //   // boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});

// 7) Стилизация Кнопки для сохранения:
const ButtonStyleSave = styled(Button)({
  fontFamily: FONT_FAMILY,
  fontWeight: 400,
  color: COLOR_TEXT_MAIN,
  // boxShadow: 'none',
  textTransform: 'none',
  // cursor: 'default',
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  backgroundColor: COLOR_TEXT_ACCENT,
  // borderColor: '#0063cc',
  // borderRadius: 10,
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  '&:hover': {
    backgroundColor: COLOR_TEXT_SUBMAIN,
    // borderColor: '#0062cc',
    // boxShadow: 'none',
    color: COLOR_TEXT_MAIN,
  },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  '&:focus': {
    backgroundColor: COLOR_TEXT_SUBMAIN,
    // borderColor: '#0062cc',
    // boxShadow: 'none',
    color: COLOR_TEXT_MAIN,
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
  '&:disabled': {
    // boxShadow: 'none',
    // borderColor: '#005cbf',
    backgroundColor: COLOR_TEXT_ACCENT,
    color: COLOR_TEXT_MAIN,
  },
});



export {
  ButtonStyleNav, 
  ButtonStyleHero,
  ButtonStyleHeroDisabled, 
  ButtonStyleSec, 
  ButtonStyleFoot,
  ButtonStyleUser,
  ButtonStyleSave,
}
