import { createAction } from '@reduxjs/toolkit';

// ------------------------------------------------------> Внешние Новости - GLOBAL NEWS

const actNewsGlobal = createAction('@getNewsGlobal/SUCCSESS');
const actNewsGlobalError = createAction('@getNewsGlobal/ERROR');
const actNewsGlobalRequest = createAction('@getNewsGlobal/REQUEST');

// ------------------------------------------------------> Внутринние Новости - LOCAL NEWS (Public/Active/Check)
const actNewsLocal = createAction('@getNewsLocal/SUCCSESS');
const actNewsLocalError = createAction('@getNewsLocal/ERROR');
const actNewsLocalRequest = createAction('@getNewsLocal/REQUEST');

// ------------------------------------------------------> Конкретная Новость (нужно если переазагрузка страницы):
// actNewsCurrent - это внутренняя запись в State, без запроса на бэк
const actNewsCurrent = createAction('@actGetNewsCurrent/SUCCSESS');
const actNewsLocalCurrent = createAction('@actGetNewsLocalCurrent/SUCCSESS');
const actNewsLocalCurrentError = createAction('@actGetNewsLocalCurrent/ERROR');
const actNewsLocalCurrentRequest = createAction('@actGetNewsLocalCurrent/REQUEST');

// ------------------------------------------------------> Внутринние Новости - Public NEWS All
const actNewsPublic = createAction('@getNewsPublic/SUCCSESS');
const actNewsPublicError = createAction('@getNewsPublic/ERROR');
const actNewsPublicRequest = createAction('@getNewsPublic/REQUEST');

export {
    actNewsGlobal, 
    actNewsGlobalError, 
    actNewsGlobalRequest, 
    actNewsLocal, 
    actNewsLocalError, 
    actNewsLocalRequest,
    actNewsCurrent,
    actNewsLocalCurrent, 
    actNewsLocalCurrentError, 
    actNewsLocalCurrentRequest,
    actNewsPublic, 
    actNewsPublicError,
    actNewsPublicRequest,
}

