// TODO: Пути - если будут еще добавлені пути, то их нужно 
// внести в App.js - там есть проверка на несуществующий путь

const route = {
    home: '/',
    // homeName - аналогичная к home, рендерится главная страница 
    // (при запуске просто автоматически прописывается путь /buildhub)
    homeName: '/buildhub',
    // До Публичного и Приватного роута было иммено так:
    news: '/news',
    newsOne: '/news/:id',
    // news: '/buildhub/:id',
    register: '/register',
    login: '/login',
    developmentCompany: '/development/:id',
    development: '/development',
    building: '/building',
    buildingCompany: '/building/:id',
    design: '/design',
    designCompany: '/design/:id',
    // Не работают пока что:
    // supply: '/supply',
    // event: '/event',
    // job: '/job',
    user: '/user',
}


export default route;