// !!! Стилизация ТЕКСТА:

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import { FONT_FAMILY, FONT_FAMILY_LOGO, COLOR_TEXT_LOGO, COLOR_TEXT_SUBMAIN, } from '../../../helpers/constants';

const LogoStyle = styled(Typography)({
  fontFamily: FONT_FAMILY_LOGO,
  color: COLOR_TEXT_LOGO,
  letterSpacing: '0.15em',
  textTransform: 'uppercase',
})

const TypographyStyle = styled(Typography)({
  fontFamily: FONT_FAMILY,
  color: COLOR_TEXT_SUBMAIN,
})

const TextFieldStyle = styled(TextField)({
  // Стиль плейсхолдера
  'label': {
  fontFamily: FONT_FAMILY,
  },
  // Для звичайних розділів (інпут) :
  'input': {
  fontFamily: FONT_FAMILY,
  color: COLOR_TEXT_SUBMAIN
  },
  // Для розділу Про Компанію:
  'textarea': {
  fontFamily: FONT_FAMILY,
  color: COLOR_TEXT_SUBMAIN,
  },
  // Для розділу Типи будівель:
  'span': {
  fontFamily: FONT_FAMILY, 
  color: COLOR_TEXT_SUBMAIN,
  }
})

const FormHelperTextStyle = styled(FormHelperText)({
  fontFamily: FONT_FAMILY,
  color: COLOR_TEXT_SUBMAIN,
  opacity: 0.8,
})



export {
  LogoStyle,
  TypographyStyle, 
  TextFieldStyle,
  FormHelperTextStyle,
}
