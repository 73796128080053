import { createAction } from '@reduxjs/toolkit';

// // ------------------------------------------------------> Создание Компании при Первом Запуске:
// const actCreateCompany = createAction('@CreateCompany/SUCCSESS');
// const actCreateCompanyError = createAction('@CreateCompany/ERROR');
// const actCreateCompanyRequest = createAction('@CreateCompany/REQUEST');

// ------------------------------------------------------> Получаем список Компаний:
const actGetCompany = createAction('@GetCompany/SUCCSESS');
const actGetCompanyError = createAction('@GetCompany/ERROR');
const actGetCompanyRequest = createAction('@GetCompany/REQUEST');

// ------------------------------------------------------> Получаем полную информацию о Компании:
const actGetCompanyCurrent = createAction('@GetCompanyCurrent/SUCCSESS');
const actGetCompanyCurrentError = createAction('@GetCompanyCurrent/ERROR');
const actGetCompanyCurrentRequest = createAction('@GetCompanyCurrent/REQUEST');

// ------------------------------------------------------> Получаем список Компаний (Девелоперы):
const actGetCompanyDeveloper = createAction('@GetCompanyDeveloper/SUCCSESS');
const actGetCompanyDeveloperError = createAction('@aGetCompanyDeveloper/ERROR');
const actGetCompanyDeveloperRequest = createAction('@GetCompanyDeveloper/REQUEST');

// ------------------------------------------------------> Получаем список Компаний (Строители):
const actGetCompanyBuilder = createAction('@GetCompanyBuilder/SUCCSESS');
const actGetCompanyBuilderError = createAction('@GetCompanyBuilder/ERROR');
const actGetCompanyBuilderRequest = createAction('@aGetCompanyBuilder/REQUEST');

// ------------------------------------------------------> Получаем список Компаний (Проектировщики):
const actGetCompanyDesigner = createAction('@GetCompanyDesigner/SUCCSESS');
const actGetCompanyDesignerError = createAction('@GetCompanyDesigner/ERROR');
const actGetCompanyDesignerRequest = createAction('@GetCompanyDesigner/REQUEST');

// ------------------------------------------------------> Добавляем/Удаляем Компанию из Favorite:
const actChangeFavorite = createAction('@ChangeFavorite/SUCCSESS');
const actChangeFavoriteError = createAction('@ChangeFavorite/ERROR');
const actChangeFavoriteRequest = createAction('@ChangeFavorite/REQUEST');

// ------------------------------------------------------> Получаем список Любимых Компаний:
const actGetCompanyFavorite = createAction('@GetCompanyFavorite/SUCCSESS');
const actGetCompanyFavoriteError = createAction('@GetCompanyFavorite/ERROR');
const actGetCompanyFavoriteRequest = createAction('@aGetCompanyFavorite/REQUEST');

// ------------------------------------------------------> Добавляем/Удаляем Like у Компании:
const actChangeLike = createAction('@ChangeLike/SUCCSESS');
const actChangeLikeError = createAction('@aChangeLike/ERROR');
const actChangeLikeRequest = createAction('@ChangeLike/REQUEST');

// ------------------------------------------------------> Поиск Компании по имени (Filter)
// FilterCompany - это внутренний фильтр, без запроса на бэк (пока что)
// const actFilterCompany = createAction('@FilterCompany/SUCCSESS');
// const actFilterCompanyError = createAction('@aFilterCompany/ERROR');
// const actFilterCompanyRequest = createAction('@aFilterCompany/REQUEST');

export {
  actGetCompany,
  actGetCompanyError,
  actGetCompanyRequest,

  actGetCompanyCurrent,
  actGetCompanyCurrentError,
  actGetCompanyCurrentRequest,

  actGetCompanyDeveloper,
  actGetCompanyDeveloperError,
  actGetCompanyDeveloperRequest,

  actGetCompanyBuilder,
  actGetCompanyBuilderError,
  actGetCompanyBuilderRequest,

  actGetCompanyDesigner,
  actGetCompanyDesignerError,
  actGetCompanyDesignerRequest,

  actChangeFavorite,
  actChangeFavoriteError,
  actChangeFavoriteRequest,

  actGetCompanyFavorite,
  actGetCompanyFavoriteError,
  actGetCompanyFavoriteRequest,

  actChangeLike,
  actChangeLikeError,
  actChangeLikeRequest,

  // actFilterCompany,
  // actFilterCompanyError,
  // actFilterCompanyRequest,
};
