// ============================== 1) Преобразование даты из формата 0000-00-00 в формат 00.00.000
// function fnNewDate(params) {

//   const parseDate = new Date(params);
//   const day = parseDate.getDate().toString().padStart(2, '0');
//   const monthNumber = parseDate.getMonth();
//   const month = monthNumber + 1;
//   const montStringh = month.toString().padStart(2, '0');
//   const year = parseDate.getFullYear().toString();
//   // const fnNewDate = `${day}.${montStringh}.${year}`
//   return `${day}.${montStringh}.${year}`;
// }

function fnNewDate(date) {

  // Удаляем все символы после 10-го: (-10 работает в обратную сторону)
  date.slice(0, 10);
  const parseDate = new Date(date);
  const day = parseDate.getDate().toString().padStart(2, '0');
  const monthNumber = parseDate.getMonth();
  const month = monthNumber + 1;
  const montStringh = month.toString().padStart(2, '0');
  const year = parseDate.getFullYear().toString();
  // const fnNewDate = `${day}.${montStringh}.${year}`
  return `${day}.${montStringh}.${year}`;
}

// ============================== 2) Сортировка списка по дате:
function fnListSort(array, sortBy) {
  // list - это наш массив
  // sortBy - это параметр за каким нам нужна сортировка, передаем строку "..." и приходит строка,
  // по этому запись у нас item1[sortBy], если бы парметр приходил то было бы к примеру - item1.status

    // let costListNew = [];
    // if (list) {
    const copyList = [...array];
    const copyListSort = copyList.sort((item1, item2) => {
      if (item1[sortBy] < item2[sortBy]) {
        return 1;
      }
      if (item1[sortBy] > item2[sortBy]) {
        return -1;
      }
      return 0;
    });
    // costListNew = copyListSort;
    // }
    return copyListSort;
  }

// ============================== 3) ф-я обрезания строки и добавление "..."
const fnCutString = function (string, length) {

  // let length = 100;
  const textArray = string.split('');
  const textLength = textArray.length;

  if (textLength > length) {
    textArray.length = length;
    const textString = textArray.join('');
    const textEnd = textString + '...';
    // console.log(`Общая длина строки: ${textEnd.length}`);
    return textEnd;
  }
  else {
    return string;
  }
};

// ============================== 4) ф-я случайного перемешивания элементов массива:
function fnMixArray(arr) {
  // 1) Нашли рандомное число от 0 до 1 (не целое)
  // 2) Добавили к рандомному число элемент массива
  // 3) Отсортировали массив по значению рандомного числа
  // 4) Прошлись методом "map" и вернули только его 2-й элемент (индекс 1)
  return arr.map(item1 => [Math.random(), item1]).sort().map(item2 => item2[1])
}

// ============================== 5) ф-я фильтрации Компании за VIP статусом:
function fnVipStatus(array, index) {

  // 1) Отсортировали по VIP статусу
  // 2) Прошлись методом "map" и добавили к элементу масива позицию (нужный индекс),
  //    TODO: всего может быть 3 индекса (0, 1, 2) так как у нас массив может быть только из 3-х значений: [100, 100, 100],
  //    означает [Девелопмент, Стротиельство, Проектирование]
  // 3) Отсортировали массив по значению первого числа (в данном случае это наш индекс позиции под индексом 0)
  // 4) Прошлись методом "map" и вернули только его 2-й элемент (индекс 1)
  const copyArray = array.filter(item1 => item1.isVIP)
  .map(item2 => [item2.position[index], item2])
  .sort((prev, next) => {return prev[0] - next[0]})
  .map(item3 => item3[1])

  return copyArray
}

export { fnNewDate, fnListSort, fnCutString, fnMixArray, fnVipStatus};
