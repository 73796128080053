import { createReducer } from '@reduxjs/toolkit';


import {
  actRegister,
  // actRegisterError,
  // actRegisterRequest,
  actLogin,
  // actLoginError,
  // actLoginRequest,
  actLogout,
  // actLogoutError,
  // actLogoutRequest,

  actCurrentUser,
  actCurrentUserError,
  // actCurrentUserRequest,

  actUpdateUser,
  // actUpdateUserError,
  // actUpdateUserRequest,

  actUpdateUserEmail,
  // actUpdateUserEmailError,
  // actUpdateUserEmailRequest,

  actUpdateUserPass,
  // actUpdateUserPassError,
  // actUpdateUserPassRequest,

  actDeleteUser,
  // actDeleteUserError,
  // actDeleteUserRequest,
} from '../actions/userAuth';

import {
actUpdateCompanyContacts,
// actUpdateCompanyContactsError,
// actUpdateCompanyContactsRequest,

actDeleteCompany,
// actDeleteCompanyError,
// actDeleteCompanyRequest,
} from '../actions/userCompany';

import {
  actChangeFavorite,
  // actChangeFavoriteError,
  // actChangeFavoriteRequest,
} from '../actions/company';


// --------------------------------------------------> User State
// Дефолтный Юзер
const defaultUser = {
  // favorite, _id - указали конкретно дефолтное значение так как есть в коде привязка к глоб.State
  favorite: [],
  _id: null
}

const userReducer = createReducer(defaultUser, {
 [actRegister]: (state, action) => action.payload.data,

 [actLogin]: (state, action) => action.payload.data.user,

 [actLogout]: (state, action) => defaultUser,

 [actCurrentUser]: (state, action) => action.payload.data.user,

 [actChangeFavorite]: (state, action) => action.payload.data.user,

 // --------- Если текущий пользователь не приходи, то мы обнуляем Юзера (обнуляем токен):
 [actCurrentUserError]: (state, action) => defaultUser,
 
 //  ------- Изменение Контактов:
 [actUpdateUser]: (state, action) => action.payload.data.user,

//  ------- Изменение Почты:
[actUpdateUserEmail]: (state, action) => action.payload.data.user,

//  ------- Изменение Пароля:
[actUpdateUserPass]: (state, action) => action.payload.data.user,


//  ------- Изменение Контактов Компании:
[actUpdateCompanyContacts]: (state, action) => action.payload.data.user,

//  ------- Удаление Компании (очищаем наши Контакты, потому что с бэка не приходи обновленная инфа про Юзера):
[actDeleteCompany]: (state, action) => {
  const copyState = state
  copyState.phoneCompany = ''
  return copyState
},

//  ------- Удаление Юзера:
[actDeleteUser]: (state, action) => defaultUser,

},
);


export { userReducer};
