// FIXME: Актуальность ссылок + добавить константы на разрешение экранов

// TODO: Статические переменные!

// Базовый URL на наш сервер:
// const API_URL = "https://aqueous-ravine-24119.herokuapp.com/"
// const API_URL = "https://ancient-shore-81156.herokuapp.com/"
// const API_URL = "http://localhost:3001/"
//const API_URL = "https://api.buildhub.pp.ua/"
const API_URL = "https://api.bildhab.com/"

// Цвет нашего Loading:


// Название нашего портала:
const BUILD_HUB = 'BILDHAB'

// Обратная связь:
const BUILDHUB_EMAIL_INFO = 'bildhab.info@gmail.com'

// Список отвечает базе данных: db-categoryContractor (применено для условий)
const DEVELOPMENT = 'Девелопмент'
const GENPIDRYAD = 'Генпідрядник'
const PIDRYAD = 'Підрядник'
const GENPROJECT = 'Генпроектувальник'
const PROJECT = 'Проектувальник'

// Список отвечает базе данных: db-categoryDirection (применено для условий)
const CIVIL = 'Цивільне'
const INDUSTRIAL = 'Промислове'
const TRANSPORT = 'Транспортне'

const COUNTRY = 'Україна'

// Типы Юзеров:
const USER = 'User'
const COMPANY = 'Company'
const SuperUSER = 'SuperUser'

// Колличество фото-проектов которые Юзер может загрузить (можно увеличить здесь):
const NUMBER_PHOTO_CONSTR = '12'
const NUMBER_PHOTO_DESIGN = '6'

// Колличество символов в Новостях:
const NUMBER_SYMBOL_TITLE = 75 //100
const NUMBER_SYMBOL_TEXT = 75 //107


// ===============================================> Ошибки:
// Ошибка ложного токена(Ошибка одинаковая с бэком):
const ERROR_ACCESS_WRONG = 'Пройдіть авторизацію'
const ERROR_UPDATE = 'Стався збій при завантажені — спробуйте ще раз або пізніше!'
// Компания или Новости не найдены:
const ERROR_NOT_FOUND = 'Not Found'

// ===============================================> Цветовая гамма:
// 1.1) Цвет загрузки при Регистрации и Логинизации (синій):
const COLOR_LOAD_ENTER = '#1976D2'
// '#f1ce41' - желтый
// 1.2) Цвет Текса Логинизации и Регистрации (желтый):
// Жовтий:
// const COLOR_TEXT_FORM = '#f1ce41'
// Синій:
const COLOR_TEXT_FORM = '#1976D2'

// 2) Цвет загрузки при создании, редактировании Компании на беблом фоне (синий):
const COLOR_LOAD_SAVE = '#2196f3'

// 3.1) Цвет текста (синий):
const COLOR_TEXT_ACCENT = '#1976D2' 
//#2196f3
// #2656FF - по дизайну
// 3.2) Белый
const COLOR_TEXT_MAIN = '#FFFFFF'
// 3.3) Черный
const COLOR_TEXT_SUBMAIN = '#000000'
// 3.4.1) Серый
const COLOR_TEXT_BACK = '#EFF1F4'
// 3.4.2) Серый для лого редактирования фото обьектов (Строительство, Проектирование):
const COLOR_TEXT_BACK_LOGO = 'rgba(0, 0, 0, 0.54)'
// 3.5) Цвет ЛОГО (салатовий):
const COLOR_TEXT_LOGO = '#D7E626'

// 4) Темно-синий (как на Герое) - для Логина и Регистрации:
const COLOR_GROUND_HERO = '#32414C'

// Цвет ошибки (светло красный):
const COLOR_TEXT_ERROR = '#CF5E2B'
// Цвет обложки (бирюзоватый):
const COLOR_GROUND_MAIN = '#e8fdff'
// Цвет списка Компаний + Верхней карточки Компании (светло-фиолетовый):
const COLOR_GROUND_LIST = '#cfe8fc'


// ===============================================> Шрифт:
// 1) Основниий
const FONT_FAMILY = 'Inter'
// 2) Для ЛОГО
const FONT_FAMILY_LOGO = 'Blanka'
// 3) Для ГЕРОЯ
const FONT_FAMILY_HERO = 'Capture-smallz'

// ===============================================> Условия для рендера одинаковых Компонентов на разных страницах:
// Только Кабинет Юзера:
const RENDER_PAGE_USER = 'userPage'
// Общие страницы:
const RENDER_PAGE_GENERAL = 'generalPage'


export { 
    API_URL, 
    BUILD_HUB,
    BUILDHUB_EMAIL_INFO,
    DEVELOPMENT, 
    GENPIDRYAD, 
    PIDRYAD, 
    GENPROJECT, 
    PROJECT,
    CIVIL,
    INDUSTRIAL,
    TRANSPORT,
    COUNTRY, 
    USER,
    COMPANY,
    SuperUSER,
    ERROR_ACCESS_WRONG,
    ERROR_UPDATE,
    ERROR_NOT_FOUND,
    COLOR_LOAD_SAVE,
    COLOR_LOAD_ENTER,
    COLOR_TEXT_ACCENT,
    COLOR_TEXT_MAIN,
    COLOR_TEXT_SUBMAIN,
    COLOR_TEXT_BACK,
    COLOR_TEXT_BACK_LOGO,
    COLOR_TEXT_ERROR,
    COLOR_TEXT_FORM,
    COLOR_TEXT_LOGO,
    COLOR_GROUND_HERO,
    COLOR_GROUND_MAIN,
    COLOR_GROUND_LIST,
    FONT_FAMILY,
    FONT_FAMILY_LOGO,
    FONT_FAMILY_HERO,
    RENDER_PAGE_USER,
    RENDER_PAGE_GENERAL,
    NUMBER_PHOTO_CONSTR,
    NUMBER_PHOTO_DESIGN,
    NUMBER_SYMBOL_TITLE,
    NUMBER_SYMBOL_TEXT,
 };
