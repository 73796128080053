import React from 'react';

// Импорт из библиотеки:
import { Box } from '@mui/system';

// Импорт моих компонентов:
import WrongRoute from '../../components/Elements/WrongRoute/WrongRoute';


export default function Message() {

  return (
    <section className="App-Message">
      <Box sx={{ width: { xs: '320px', sm: '768px', lg: '1200px' }, marginX: 'auto', marginBottom: '10px', padding: '15px', paddingBottom: '0px' }}>
      <WrongRoute text='Помилка 404 - Така сторінка не знайдена'/>
      </Box>
    </section>
  );
}
