import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  actCreateCompany,
  // actCreateCompanyError,
  // actCreateCompanyRequest,

  actGetUserCompany,
  // actGetUserCompanyError,
  // actGetUserCompanyRequest,

  actDeleteCompanyPhoto,
  // actDeleteCompanyPhotoError,
  // actDeleteCompanyPhotoRequest,

  actDeleteCompany,
  // actDeleteCompanyError,
  // actDeleteCompanyRequest,

  actUpdateCompanyStatus,
  // actUpdateCompanyStatusError,
  // actUpdateCompanyStatusRequest,
  
  actUpdateCompany,
  // actUpdateCompanyError,
  // actUpdateCompanyRequest,

  actUpdateCompanyPhoto,
  // actUpdateCompanyPhotoError,
  // actUpdateCompanyPhotoRequest,

  actUpdateCompanyLogo,
  // actUpdateCompanyLogoError,
  // actUpdateCompanyLogoRequest,

  actGetUserCompanyPhoto,
  // actGetUserCompanyPhotoError,
  // actGetUserCompanyPhotoRequest,
} from '../actions/userCompany';

import {
  // actRegister,
  // actRegisterError,
  // actRegisterRequest,
  // actLogin,
  // actLoginError,
  // actLoginRequest,
  // actLogout,
  // actLogoutError,
  actLogoutRequest,
  // actCurrentUser,
  // actCurrentUserError,
  // actCurrentUserRequest,
  actDeleteUser,
  // actDeleteUserError,
  // actDeleteUserRequest,
} from '../actions/userAuth';

import {
  actCreateNews,
  // actCreateNewsError,
  // actCreateNewsRequest,

  actGetUserNews,
  // actGetUserNewsError,
  // actGetUserNewsRequest,

  actUpdateUserNews,
  // actUpdateUserNewsError,
  // actUpdateUserNewsRequest,

  actUpdateUserNewsPhoto,
  // actUpdateUserNewsPhotoError,
  // actUpdateUserNewsPhotoRequest,

  actUpdateUserNewsStatusAll,
  // actUpdateUserNewsStatusAllError,
  // actUpdateUserNewsStatusAllRequest,

  actDeleteNews,
  // actDeleteNewsError,
  // actDeleteNewsRequest,
} from '../actions/userNews';

import { 
  actGetCompanyFavorite,
  // actGetCompanyFavoriteError,
  actGetCompanyFavoriteRequest,
} from '../actions/company';


// --------------------------------------------------> State -> User:

// null - не менять так мы на него ссылаемся при ренедере Компании (с бэка тоже приходит null)
const company = createReducer(null, {

  [actLogoutRequest]: (state, action) => null,
  // [actLogoutError]: (state, action) => null,
  // [actLogout]: (state, action) => null,

  // 2) В случае с редактированием Компании
 [actCreateCompany]: (state, action) => action.payload.data.company,
 [actGetUserCompany]: (state, action) => action.payload.data.company,
 [actUpdateCompanyStatus]: (state, action) => action.payload.data.company,
 [actUpdateCompany]: (state, action) => action.payload.data.company,

 [actUpdateCompanyLogo]: (state, action) => action.payload.data.company,

 [actDeleteCompany]: (state, action) => null,

 // При удалении Юзера:
 [actDeleteUser]: (state, action) => null,
},
);



const photo = createReducer([], {

  // Обнулили после выхода юзера:
  [actLogoutRequest]: (state, action) => [],

  [actGetUserCompanyPhoto]: (state, action) => action.payload.data.photo,

  [actUpdateCompanyPhoto]: (state, action) => [...state, action.payload.data.photo],

  // При удалении фотки в action.payload приходит id(от api-userCompany), дальше фильтруем что бы в State отображались фото без удаленного:
  [actDeleteCompanyPhoto]: (state, action) => state.filter(item => item._id !== action.payload),

  [actDeleteCompany]: (state, action) => [],

  // При удалении Юзера:
  [actDeleteUser]: (state, action) => [],

 },
 );

 const news = createReducer([], {
  // Обнулили после выхода юзера:
  [actLogoutRequest]: (state, action) => [],


  // Распыляем существующий массив и добавляем новую позицию:
  [actCreateNews]: (state, action) => [...state, action.payload.data.news],
  
  [actGetUserNews]: (state, action) => action.payload.data.news,

  // TODO: При обновлении новости, не отображало обновленную информацию, потому что с бэка приходил объект, по этому мы взяли пердыдущий State
  // [actUpdateUserNews]: (state, action) => action.payload.data.news,
  [actUpdateUserNews]: (state, action) => {
    // 1) Сначала отфильтровали существующий список новостей, исключили Айди новсти которую редактируем:
    let arrayFilter = state.filter(item => item._id !== action.payload.data.news._id)
    // 2) Распылили отфильтрованый массив и добавили новость которую отредактировали
    let arrayNew = [...arrayFilter, action.payload.data.news]
    return arrayNew
  },

  [actUpdateUserNewsPhoto]: (state, action) => action.payload.data.news,
  
  [actUpdateUserNewsStatusAll]: (state, action) => action.payload.data.news,

  // При удалении в action.payload приходит id(от api-userNews), дальше фильтруем что бы в State отображались без удаленного:
  [actDeleteNews]: (state, action) => state.filter(item => item._id !== action.payload),

  [actDeleteCompany]: (state, action) => [],

  // При удалении Юзера:
  [actDeleteUser]: (state, action) => [],

 },
)

const favoriteCompany = createReducer([], {
  // Обнулили после выхода юзера:
  [actLogoutRequest]: (state, action) => [],

  [actGetCompanyFavorite]: (state, action) => action.payload.data.company,

  // TODO: Для рендера бралось старое значение State(баг какой то), по этому при запросе мы сбросили его до дефолтного состояния:
  [actGetCompanyFavoriteRequest]: (state, action) => [],

  // При удалении Юзера:
  [actDeleteUser]: (state, action) => [],

 },
)



export default combineReducers({ company, photo, news, favoriteCompany});
